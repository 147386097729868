import React from 'react';
import styled from '@emotion/styled';
import { Loading, TextInput, Checkbox, FONTS, PALETTE, Select } from '@sayrhino/rhino-shared-js';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Subtitle,
  Banner,
  ButtonGroup,
  StyledCloseButton,
  HeaderWrapper,
  Splitter,
  HeaderText,
  SaveButton,
  PropertyOwnerFormSubtitle
} from './Styled';
import useOverlay from '../utils/overlay';
import usePropertyOwnerDetails from 'api/v2/usePropertyOwnerDetails';
import usePropertyOwnerForm from './usePropertyOwnerForm';
import { centsToUSDFormatter, sanitizeCurrencyInput } from '../utils';
import { CoverageTypeEnum, FormMode, PropertyOwnerClaimClassification } from '../interfaces';
import { CoverageTypeSwitch } from './CoverageTypeSwitch';
import Decimal from 'decimal.js';
import RentersInsuranceRequirementForm from './RentersInsuranceRequirementsForm';
import InvitationRequirement from './InvitationRequirement';

const StyledTextInput = styled(TextInput)({ marginTop: '24px' });
const StyledHeaderText = styled(HeaderText)({ marginBottom: '16px' });
const SwitchWrapper = styled.div({
  marginTop: '24px'
});
const StyledCopyP = styled.p([
  FONTS.p2Medium,
  {
    marginTop: '24px',
    color: PALETTE.neutral55
  }
]);
const StyledCheckBoxWrapper = styled.div({
  marginTop: '24px'
});
const TagSelectWrapper = styled.div({ marginBottom: '-18px' });
const StyledSelect = styled(Select)({ width: '176px' });

const PropertyOwnerForm = () => {
  const {
    handleSubmit,
    name,
    setName,
    nameError,
    setNameError,
    coverageMultiplierLimitsError,
    setCoverageMultiplierLimitsError,
    defaultCoverageAmount,
    setDefaultCoverageAmount,
    coverageMultiplierLimits,
    setCoverageMultiplierLimits,
    canUpdateClaimClassification,
    defaultCoverageType,
    setDefaultCoverageType,
    maxCoverageAmount,
    setMaxCoverageAmount,
    RIRequiredSelection,
    setRIRequiredSelection,
    liabilityCoverage,
    setLiabilityCoverage,
    isLoading,
    isMaxCoverageAmountChecked,
    setIsMaxCoverageAmountChecked,
    errorMessageForAmount,
    setErrorMessageForAmount,
    formMode,
    maxCoverageError,
    getDefaultLiabilitySelection,
    exclusiveMarketingAgreementExists,
    setExclusiveMarketingAgreementExists,
    claimClassificationTag,
    setClaimClassificationTag,
    requiresInvitation,
    setRequiresInvitation
  } = usePropertyOwnerForm();

  const navigate = useNavigate();

  const isDefaultCoverageAmount: boolean = defaultCoverageType === CoverageTypeEnum.DOLLAR_AMOUNT;
  const isEditMode: boolean = formMode === FormMode.EDIT;

  const TAG_OPTIONS = [
    { label: 'VIP', value: PropertyOwnerClaimClassification.VIP },
    { label: 'SIU', value: PropertyOwnerClaimClassification.SIU }
  ];
  //This function returns the tag as a selection so it is a clearable options instead of text
  const claimClassificationValue = (tag: string) => {
    if (tag === PropertyOwnerClaimClassification.VIP) return TAG_OPTIONS[0];
    if (tag === PropertyOwnerClaimClassification.SIU) return TAG_OPTIONS[1];
    return 'optional';
  };
  const [updateDefaultCoverageAmount, setUpdateDefaultCoverageAmount] = React.useState<number>(defaultCoverageAmount);

  return (
    <>
      <HeaderWrapper>
        <StyledCloseButton onClick={() => navigate(-1)} aria-label="Leave create property owner form" />
        <StyledHeaderText>{isEditMode ? 'Edit' : 'Create'} Property Owner</StyledHeaderText>
      </HeaderWrapper>
      <Splitter />
      <Banner>
        <PropertyOwnerFormSubtitle>
          Property Owner
          {canUpdateClaimClassification && (
            <TagSelectWrapper>
              <StyledSelect
                data-cy="propertyOwnerClaimClassification"
                options={TAG_OPTIONS}
                label="Claim Classification"
                onChange={(tag: any) => {
                  tag ? setClaimClassificationTag(tag.value) : setClaimClassificationTag('');
                }}
                isClearable={true}
                placeholder={'optional'}
                value={claimClassificationValue(claimClassificationTag)}
                id="PropertyOwnerClassification"
              />
            </TagSelectWrapper>
          )}
        </PropertyOwnerFormSubtitle>

        <StyledTextInput
          id="propertyOwnerName"
          label="Name"
          value={name}
          onChange={(e) => {
            setNameError('');
            setName(e.target.value);
          }}
          error={!!nameError}
          subtext={nameError}
        />

        <Subtitle>Coverage Type </Subtitle>
        <StyledCopyP>
          Standard security deposit amount for this property owner's properties. May be a dollar amount or a rent
          multiplier. This will be set as the default for manual invitations sent to renters.
        </StyledCopyP>
        <SwitchWrapper>
          <CoverageTypeSwitch
            coverageError={false}
            coverageType={defaultCoverageType}
            value={defaultCoverageAmount}
            handleCoverageDollarValue={(e) => {
              if (errorMessageForAmount) setErrorMessageForAmount('');
              setDefaultCoverageAmount(sanitizeCurrencyInput(e));
              setUpdateDefaultCoverageAmount(sanitizeCurrencyInput(e));
            }}
            onSelect={(e) => {
              setDefaultCoverageAmount(defaultCoverageType === CoverageTypeEnum.MULTIPLIER ? 0 : 1);
              setDefaultCoverageType(e);
            }}
            setCoverageValue={setDefaultCoverageAmount}
            updateDefaultCoverageAmount={updateDefaultCoverageAmount}
            coverageDollarError={errorMessageForAmount}
            subText={errorMessageForAmount}
            label="default-coverage-type-switch"
            id="coverage-rules"
            setMultipliers={setCoverageMultiplierLimits}
            multipliers={coverageMultiplierLimits}
            coverageMultiplierLimitsError={coverageMultiplierLimitsError}
            setCoverageMultiplierLimitsError={setCoverageMultiplierLimitsError}
          />
        </SwitchWrapper>
        {isDefaultCoverageAmount && (
          <StyledCheckBoxWrapper>
            <Checkbox
              id="set-max-coverage"
              label="Set max coverage"
              checked={isMaxCoverageAmountChecked}
              onCheckedChange={(isMaxCoverageAmountChecked) => {
                setIsMaxCoverageAmountChecked(isMaxCoverageAmountChecked);
                if (!isMaxCoverageAmountChecked) setMaxCoverageAmount(undefined);
              }}
            />
            {isMaxCoverageAmountChecked && (
              <StyledTextInput
                value={
                  maxCoverageAmount === 0
                    ? ''
                    : maxCoverageAmount && centsToUSDFormatter(new Decimal(maxCoverageAmount))
                }
                onChange={(e) => setMaxCoverageAmount(sanitizeCurrencyInput(e))}
                id="max-coverage-input"
                placeholder={'e.g. $1,500'}
                maxLength={11}
                label="Set max coverage"
                subtext={maxCoverageError}
                error={!!maxCoverageError}
              ></StyledTextInput>
            )}
          </StyledCheckBoxWrapper>
        )}

        <RentersInsuranceRequirementForm
          liabilityCoverage={liabilityCoverage}
          setLiabilityCoverage={setLiabilityCoverage}
          getDefaultLiabilitySelection={getDefaultLiabilitySelection}
          RIRequiredSelection={RIRequiredSelection}
          setRIRequiredSelection={setRIRequiredSelection}
          exclusiveMarketingAgreementExists={exclusiveMarketingAgreementExists}
          setExclusiveMarketingAgreementExists={setExclusiveMarketingAgreementExists}
        />
        <InvitationRequirement
          requiresInvitation={requiresInvitation}
          setRequiresInvitation={setRequiresInvitation}
        />
      </Banner>

      <ButtonGroup aria-label="submit">
        <SaveButton
          id="submit-property-owner-button"
          variant="interaction"
          type="submit"
          children="Save"
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
};

const PropertyOwnerFormOverlay = () => {
  const { setShowOverlay } = useOverlay();
  const { propertyOwnerId } = useParams();
  const { isSuccess } = usePropertyOwnerDetails(Number(propertyOwnerId)) ?? {};
  React.useEffect(() => {
    setShowOverlay(true);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  if (!propertyOwnerId) {
    return <PropertyOwnerForm />;
  }
  return isSuccess ? <PropertyOwnerForm /> : <Loading />;
};

export default PropertyOwnerFormOverlay;
