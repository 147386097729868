import axios from 'axios';
import { csrfToken } from 'utils/document';

interface IPropertyOwnerInfo {
  id?: number;
  name: string;
  default_coverage_type: string;
  coverage_multiplier_limits?: Array<string> | null;
  default_coverage_amount_cents?: number;
  max_coverage_amount_cents?: number;
  claim_classification?: string;
  requires_invitation?: boolean | null;
  renters_insurance_requirement_attributes?: {
    requires_renters_insurance: boolean | null;
    has_exclusive_agreement: boolean | null;
    liability_coverage?: number | null;
    id: number | null;
  };
}

const createPropertyOwner = async (propertyOwnerInfo: IPropertyOwnerInfo) => {
  const {
    id,
    name,
    default_coverage_amount_cents,
    coverage_multiplier_limits,
    default_coverage_type,
    max_coverage_amount_cents,
    requires_invitation,
    renters_insurance_requirement_attributes,
    claim_classification
  } = propertyOwnerInfo;

  const token = csrfToken();

  const response = await axios.post<IPropertyOwnerInfo>(
    '/v2/admin/property_owners',
    {
      id,
      name,
      default_coverage_amount_cents,
      coverage_multiplier_limits,
      default_coverage_type,
      max_coverage_amount_cents,
      requires_invitation,
      renters_insurance_requirement_attributes,
      claim_classification
    },
    {
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return response;
};

export default createPropertyOwner;
