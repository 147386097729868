import Decimal from 'decimal.js';
import { IPendingChangeset } from '../../insurance_policy/entities';
import { DOLLAR_AMOUNT, MULTIPLIER, NO_COVERAGE, PROPERTY_DEFAULT } from './utils/constants';

export type ICancellationRequestStatus = 'pending' | 'accepted' | 'declined' | 'autocancelled';

export interface IStates {
  states: IStateOption[];
}

export interface IStateOption {
  value: string;
  label: string;
}

export interface IAdministrators {
  administrators: IAdministrator[];
}
export interface IClaimStatusOption {
  value: string;
  label: string;
}

export interface IClaimSavedAccount {
  id: number;
  nickname: string;
}

export interface IAdministrator {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  name_or_email: string;
  initials: string;
}

export interface IClaimCategories {
  categories: IClaimCategory[];
}

export interface IClaimCategory {
  category: string;
  subcategories: string | null;
}

export interface IClaimsAnalysts {
  administrators: IClaimsAdministrators[];
}

export interface IClaimsAdministrators {
  id: number;
  name_or_email: string;
  initials: string;
}

export interface ICancellationRequest {
  id: number;
  reason: number;
  reason_string: string;
  roommate_email: string | null;
  other_reason: string | null;
  terminated_at: string | null;
  effective_from: string | null;
  response_deadline: string | null;
  info: string | null;
  status: ICancellationRequestStatus;
  early_termination: boolean;
  reason_complement: string | null;
}

export interface IClaimAttributes {
  administrator?: string;
  amount_cents: number;
  claim_number: string;
  claim_type: string;
  created_at: string;
  id: number;
  property_name: string;
  property_address: string;
  remaining_amount_cents: number;
  renter_full_name: string;
  status: string;
  status_detail?: IStatusDetail[] | undefined;
}

export interface IRelatedClaim {
  claim_number: string;
  status: ClaimStatus;
  claim_type: string;
}

export interface IPolicyAttributesInClaim {
  id: number;
  renter_full_name: string;
  renter_email: string;
  remaining_coverage_amount_cents: number;
  coverage_amount_cents: number;
  policy_number: string;
  policy_coverage_dates: string;
  lease_dates: string;
  claims_reporting_window: boolean;
  bond_version: string;
}

interface ILossOfRentInfo {
  arrears_amount_cents: number;
  date_of_loss: Date;
  id?: number;
  late_fees_cents: number;
  loss_of_rent_type: number | string;
  other_fees_cents: number;
}

interface IACHInfo {
  routing_number: string | null;
  account_number: string | null;
  save_for_future_use: boolean | null;
  nickname?: string;
}

interface IACHBankID {
  saved_bank_account_id: number;
}

interface ICheckingInfo {
  address_line_one: string;
  address_line_two: string;
  google_place_id?: string;
  address_city: string;
  address_state: string;
  address_zip: string;
}

interface IAttachment {
  blob_id: string;
  filename: string;
}

export interface IMailAddress {
  id: number;
  address_line_one: string;
  google_place_id?: string;
  address_line_two: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  nickname: string;
}

export interface IClaimStatusNotification {
  title?: string;
  label: string;
  severity: string;
}
export interface IClaimStatusAction {
  title: string;
  label: string;
  severity: string;
}

export interface IClaim {
  claim_number: string;
  renter_full_name: string;
  id: number;
  status: ClaimStatus;
  status_notification?: IClaimStatusNotification;
  status_action?: IClaimStatusAction;
  claim_type: string;
  amount_cents: number;
  adjusted_amount_cents: number;
  insurance_policy: IPolicyAttributesInClaim;
  created_date: string;
  created_by: { full_name: string | null; email: string };
  total_amount_paid_cents: number;
  property: { id: number; full_address: string; address_line_one: string; address_zip: string; name: string };
  property_owner: { name: string; claim_classification: string | null };
  policy_holder: { name: string };
  start_date: string;
  end_date: string;
  unit_name: string;
  single_or_garden: boolean;
  related_claims: IRelatedClaim[];
  administrator: IAdministrator;
  loss_of_rents: ILossOfRentInfo[];
  date_of_loss: Date | null;
  damaged_room: string | null;
  subcategory_2: string | null;
  payment_option_selected?: string;
  bank_account: IACHInfo | null;
  attachments: IAttachment[];
  mailing_address: IMailAddress | null;
  actions: ClaimActions;
  tenant_notified_of_rent_arrears: boolean;
  earliest_date_of_loss?: string | null;
  last_day_to_submit_claim: string;
  claim_amount_label: string;
  description: string;
  tenant_vacated_unit_date: string | null;
  tenant_provided_notice_date: string | null;
  masked_destination_account_number: string | null;
  itemized_losses: Array<{ label: string; value: number }>;
  is_claim_admin: boolean;
  approved_amount_cents: number;
}

export enum WithdrawalStatus {
  PENDING = 'Transfer pending',
  FAILED = 'failed',
  IN_PROGRESS = 'Transfer in progress',
  PAYMENT_COMPLETE = 'completed',
  CANCELED = 'cancelled'
}

export enum ClaimStatus {
  NEW = 'new',
  PROCESSING = 'processing',
  ACCEPTED = 'accepted',
  DENIED = 'denied',
  WITHDRAWN = 'withdrawn',
  CLOSED_WITHOUT_PAYMENT = 'closed_without_payment',
  PAID = 'paid',
  SUBROGATION = 'subrogation',
  PAYMENT_PLAN = 'payment_plan',
  PAYMENT_PLAN_IN_PROGRESS = 'payment_plan_in_progress',
  SUBROGATION_PAID_IN_FULL = 'subrogation_paid_in_full',
  COLLECTIONS = 'collections',
  COLLECTIONS_UNPAID = 'collections_unpaid',
  COLLECTIONS_PAID = 'collections_paid'
}

export interface ClaimActions {
  withdraw: boolean;
  available_statuses: IClaimStatusOption[];
  update_claim_amount: boolean;
  edit: boolean;
}

export interface IInsurancePolicyAttributes {
  coverage_amount: string;
  coverage_dates: string;
  policy_number: string;
  property_name: string;
  property_state: string;
  remaining_coverage_amount_cents: number;
  status: IInsurancePolicyStatus;
  tenant_full_name: string;
  unit_name: string;
  status_detail: IStatusDetail[] | IStatusDetail;
  lease_start_date: string;
  lease_end_date: string;
  pending_changeset: IPendingChangeset | null;
}

export type IInsurancePolicyStatus = 'active' | 'canceled' | 'expired' | 'incomplete' | 'upcoming';

export type NotificationSeverity = 'highPriority' | 'lowPriority' | 'product' | 'success' | 'passive';
export interface IStatusDetail {
  severity?: NotificationSeverity;
  label: string;
  message?: string;
  indicator_label?: string;
  days_before_expiration?: number;
}

export interface IClaimStatusDetail {
  severity: NotificationSeverity;
  label: string;
  message?: string;
  indicator_label?: string;
}

export type IInvitationStatus = 'In Progress' | 'Sent' | 'Subscribed';

export interface IInvitationAttributes {
  address_state: string;
  address_unit: string;
  building_name: string;
  cash_deposit_amount: string;
  cash_deposit_only: boolean;
  coverage_amount: string;
  coverage_dates: string;
  coverage_months: string;
  guarantor_coverage: boolean;
  monthly_rent: string;
  required_coverage: number;
  status: IInvitationStatus;
  tenant_email: string;
  tenant_full_name: string;
}

export interface IPropertyAttributes {
  active: boolean;
  address_city: string;
  address_line_one: string;
  address_state: string;
  address_zip: string;
  building_name: string;
  id: number;
  owner_name: string;
  unit_count: number;
}

export interface IPropertyDetails {
  address_city: string;
  address_line_one: string;
  address_state: string;
  address_zip: string;
  building_name: string;
  coverage_details: IDefaultCoverageRaw;
  id: number;
  policy_holder: {
    id: number;
    name: string;
  };
  property_owner: {
    id: number;
    name: string;
  };
  property_type: string;
  renters_insurance_requirement_attributes?: {
    requires_renters_insurance: boolean | null;
    has_exclusive_agreement: boolean | null;
    liability_coverage?: number | null;
    id: number | null;
  };
  renters_insurance_enabled: boolean;
}

export interface IProperty {
  property: IPropertyDetails;
}

export interface IAdminSummaryMetricsData {
  average_days_to_claim_payout_ytd: number | null;
  average_days_to_first_renter_ytd: number | null;
  invitations_completed_this_month_count: number;
  invitations_sent_this_month_count: number;
  invitations_started_total_count: number;
  invitations_this_month_total_count: number;
  invitations_total_count: number;
  new_claims_this_month_count: number;
  new_subscribers_monthly_goal: number;
  new_subscribers_this_month_count: number;
}

export interface IPropertyManagerSummaryMetricsData {
  claims_in_review_count?: number;
  covered_units_count: number;
  in_progress_invitations_count: number;
  invitations_completed_count?: number;
  renewed_policies_count: number;
  total_invitations_count: number;
  total_invitations_count_this_month: number;
  total_units_count?: number;
  units_up_for_renewal_count: number;
}

export interface IInsurancePolicySummaryMetricsData {
  partial_quotes_count: number;
  active_policies_count: number;
  total_units_count: number;
  new_policies_this_month_count: number;
  renewed_policies_last_30_days_count: number;
  expired_policies_last_30_days_count: number;
  active_policies_coverage_begun: number;
}

export interface IInvitationSummaryMetricsData {
  sent_invitations_count: number;
  subscribed_count: number;
  min_days_to_sign_up: number;
  max_days_to_sign_up: number;
  average_days_to_sign_up: number;
}

export interface IClaimSummaryTeamOverview {
  name: string;
  open_claims: number;
}
export interface IClaimSummaryMetricsData {
  avg_days_to_payout: number;
  resolved_claims_this_month?: number;
  damages: number;
  loss_of_rent: number;
  open_claims: number;
  team_overview?: IClaimSummaryTeamOverview[];
  total_funds_paid_out?: number;
  total_funds_reclaimed?: number;
}

export interface IInvitationPayload {
  id: number;
  type: 'Invitation';
  attributes: IInvitationAttributes;
}

export interface ITask {
  id: number;
  type: string;
  attributes: IInsurancePolicyAttributes;
}

export interface IFilters {
  markets?: string[];
  states: string[];
  properties: IPropertyFilter[];
  statuses: string[];
  administrators?: string[];
  claim_classifications?: string[];
}

export interface IFilterQueryParams {
  filter?: { states: string[] };
  page?: number;
  per?: number;
}

export interface IPropertyFilterOptions {
  data: IPropertyFilter[];
  meta: Required<Omit<IPaginatedMeta, 'count'>>;
}

export interface IStaticFilterOptions {
  states: string[];
  statuses: IFilterOption[];
}

export interface IPolicyFilterOptions {
  states: string[];
  statuses: {
    policy_details: IFilterOption[];
    cancellations: IFilterOption[];
    renewals: IFilterOption[];
  };
}
export interface IClaimStaticFilterOptions extends IStaticFilterOptions {
  markets: IFilterOption[];
  claim_classifications: IFilterOption[];
}

export interface IFilterOption {
  label_text: string;
  value: string;
}

export type IFilterStatusTitle = 'Claim Status' | 'Policies' | 'Invitation Status';

export interface IPropertyFilter {
  building_name: string;
  address_state: string;
}

export interface IPaginatedMeta {
  count?: number;
  total_count?: number;
  total_pages?: number;
  next_page?: number | null;
  prev_page?: number | null;
  page: number;
  per: number;
}

export interface IData<Payload, Meta = IPaginatedMeta> {
  data: Payload;
  meta: Meta;
}

export type ISearchResult = IUpdate;

export interface ISearchMeta extends IPaginatedMeta {
  count_by_collection?: ISearchResultCountsByType;
}

export interface IClaimUpdate {
  id: number;
  type: 'Claim';
  attributes: IClaimAttributes;
}

export interface IInsurancePolicyUpdate {
  id: number;
  type: 'InsurancePolicy';
  attributes: IInsurancePolicyAttributes;
}

export interface IInvitationUpdate {
  id: number;
  type: 'Invitation';
  attributes: IInvitationAttributes;
}

export interface IPropertyUpdate {
  id: number;
  type: 'Property';
  attributes: IPropertyAttributes;
}

export type IUpdate = IClaimUpdate | IInsurancePolicyUpdate | IInvitationUpdate | IPropertyUpdate;

export function isClaimUpdate(update: IUpdate): update is IClaimUpdate {
  return update.type === 'Claim';
}

export function isInsurancePolicyUpdate(update: IUpdate): update is IInsurancePolicyUpdate {
  return update.type === 'InsurancePolicy';
}

export function isInvitationUpdate(update: IUpdate): update is IInvitationUpdate {
  return update.type === 'Invitation';
}

export function isPropertyUpdate(update: IUpdate): update is IPropertyUpdate {
  return update.type === 'Property';
}

export type IVariant = 'default' | 'pending' | 'disabled' | undefined;

export enum SortOrder {
  Latest = 0,
  LastModified = 1
}

export function isSortOrder(n: any): n is SortOrder {
  return n === SortOrder.Latest || n === SortOrder.LastModified;
}

export type SearchResultType = 'Claim' | 'InsurancePolicy' | 'Invitation' | 'Property';

export function isSearchResultType(s: any): s is SearchResultType {
  switch (s) {
    case 'Claim':
    case 'InsurancePolicy':
    case 'Invitation':
    case 'Property':
      return true;
    default:
      return false;
  }
}

export interface ISearchResultCountsByType {
  InsurancePolicy: number;
  Invitation: number;
  Claim: number;
  Property: number;
}

export type CoverageType = 'PROPERTY_DEFAULT' | 'DOLLAR_AMOUNT' | 'MULTIPLIER' | 'NO_COVERAGE' | 'RP_DEPOSIT_CHARGES';
export enum CoverageTypeEnum {
  DOLLAR_AMOUNT = 'amount_cents',
  MULTIPLIER = 'multiplier',
  RP_DEPOSIT_CHARGES = 'realpage_deposit_charges',
  NO_COVERAGE = 'no_coverage'
}
export enum CoverageRuleConditionEnum {
  DEPOSIT_GT_THRESHOLD = 'deposit_gt_threshold',
  DEPOSIT_LTE_THRESHOLD = 'deposit_lte_threshold'
}
export function isCoverageType(x: string): x is CoverageType {
  return x === PROPERTY_DEFAULT || x === DOLLAR_AMOUNT || x === MULTIPLIER || x === NO_COVERAGE;
}

export type PolicySearchResult = {
  coverage_start_date: string;
  coverage_end_date: string;
  unit_name: string;
  tenant_full_name: string;
  id: number;
};

export type PolicySearchConfig = {
  host?: string;
};

export type PolicySearchResults = PolicySearchResult[];

export interface PolicyTypeaheadProps {
  propertyId?: number;
  policyId?: number;
  onSelect: (policy: PolicySearchResult) => void;
  isInvalid?: boolean;
  id: string;
}

export type IInsurancePolicy = {
  binding_date: string;
  bond_version: string;
  can_cancel: boolean;
  can_edit: boolean;
  can_terminate_early: boolean;
  cancellation_request: ICancellationRequest | null;
  coverage_amount: string;
  coverage_amount_cents: number;
  coverage_start_date: string;
  coverage_end_date: string;
  coverage_type: CoverageType;
  id: number;
  lease_start_date: string;
  lease_end_date: string;
  policy_number: string;
  policy_created_at: string;
  market_name: string;
  monthly_rent: string;
  monthly_rent_cents: number;
  rhino_plan_monthly_rate: string;
  rhino_plan_in_full_rate: string;
  property_address: string;
  property_id: number;
  remaining_coverage_amount_cents: number;
  status: string;
  status_detail: IStatusDetail[] | undefined;
  pending_changeset: IPendingChangeset | null;
  tenant_full_name: string;
  tenant_email: string;
  tenant_phone_number: string;
  tenant_id: number;
  unit_name: string;
  unit_id: number;
  upfront: boolean;
  coverage_details: IDefaultCoverageRaw;
  actions: InsurancePolicyActions;
  next_policy_id?: number;
  renewal?: IRenewal;
  has_renewal?: boolean;
  service_fees_charged: boolean;
  service_fee_cents: string;
  processing_fees_charged: boolean;
  processing_fee_cents: string;
  address_state: string;
};

export interface IRenewal {
  id: number;
  insurance_policy_id: number;
  renew_blocking_reasons?: number[];
  non_renewal_reason?: string;
}

export interface InsurancePolicyActions {
  update_address: boolean;
}

export type InsurancePolicyEditPOST = Pick<
  IInsurancePolicy,
  'lease_start_date' | 'lease_end_date' | 'coverage_amount_cents'
> & {
  unit_id: number;
  property_id: number;
  coverage: number;
};

// @TODO: update dates to take isoString
export type InsurancePolicyChangeRequest = Pick<
  IInsurancePolicy,
  'lease_start_date' | 'lease_end_date' | 'coverage_amount_cents' | 'monthly_rent_cents' | 'property_id' | 'unit_id'
> & {
  coverage_option_selected: string;
  coverage_option_value: number;
  insurance_policy_id: number;
  needs_tenant_approval: boolean;
  requester_user_id: number;
  unit_id: number;
  property_id: number;
  coverage: ICoverageSelection;
  previous_unit_id: number;
};

export type IInvitationType = 'Automated' | 'Manual';

export type IInvitation = {
  id: number;
  can_edit: boolean;
  coverage_amount: string;
  coverage_amount_cents: number;
  coverage_dates: string;
  coverage_months: number;
  coverage_months_amount: string;
  coverage_type: CoverageType;
  created_at: string;
  email: string;
  first_name: string;
  full_address: string;
  guarantor_coverage?: boolean;
  guarantor_coverage_capable?: boolean;
  last_name: string;
  lease_end_date: string;
  lease_start_date: string;
  monthly_rent: string;
  monthly_rent_cents?: {
    cents: number;
  };
  pms_is_source_of_truth: boolean;
  cash_deposit_only: boolean;
  cash_deposit_amount: string;
  cash_deposit_amount_cents?: {
    cents: number;
  };
  formatted_phone_number: string;
  property_id: number | undefined;
  required_coverage: number;
  status: string;
  tenant_full_name: string;
  unit_id: number | undefined;
  unit_name: string;
  coverage_details: IDefaultCoverageRaw;
  use_partner_enrollment?: boolean;
  enrollment_link: string;
  call_to_action_link: string;
  cash_deposit_enabled: boolean;
  declined?: boolean;
  payment_plan_decision: string;
  payment_plan_decision_version: string;
  type: IInvitationType;
  editable: boolean;  // This attributes can be removed when removing the feature flag :killing_invites
  resident_tab_message: boolean;  // This attributes can be removed when removing the feature flag :killing_invites
};

export interface IPartialQuote {
  email?: string;
  first_name?: string;
  last_name?: string;
  formatted_phone_number?: string;
  phone?: number;
  monthly_rent?: number | null;
  monthly_rent_cents?: {
    cents: number;
  };
  cash_deposit_only?: boolean;
  cash_deposit_amount?: number | null;
  cash_deposit_amount_cents?: {
    cents: number;
  };
  property_id?: number;
  unit_id?: number;
  lease_start_date?: Date;
  lease_end_date?: Date;
  coverage_amount?: string;
  coverage_amount_cents?: number;
  coverage_months?: number | null;
  full_address?: string;
  id: number;
  coverage?: ICoverageSelection;
  guarantor_coverage?: boolean;
  guarantor_coverage_capable?: boolean;
}

export type IInvitationEditPOST = {
  first_name?: string;
  last_name?: string;
  phone?: number;
  monthly_rent?: number | null;
  cash_deposit_only?: boolean;
  cash_deposit_amount?: number | null;
  coverage_amount?: string;
  coverage_amount_cents?: number;
  coverage_months?: number | null;
  property_id?: number;
  unit_id?: number;
  id?: number;
  lease_start_date?: Date;
  lease_end_date?: Date;
  coverage?: ICoverageSelection;
  guarantor_coverage?: boolean;
};

export type ChangeRequestError = {
  message: string;
};

export type UnitRequestError = {
  revision?: {
    unit?: string[];
  };
};

// The error shape from error_rescuer.rb
export interface IError {
  error: string;
}

export interface ICoverageMultiplierRaw {
  label: string;
  value: string;
}

export interface ICoverageMultiplier {
  label: string;
  value: Decimal;
}

export type DefaultCoverageType = 'DOLLAR_AMOUNT' | 'MULTIPLIER';

export interface IDefaultCoverageRaw {
  type: DefaultCoverageType;
  label: string;
  value: string | number;
}

export interface IDefaultCoverage {
  type: DefaultCoverageType;
  label: string;
  value: Decimal;
}

// For POSTing to create a change request
export interface ICoverageSelection {
  type: CoverageType;
  value?: Decimal;
}

export interface ICoverageOptions {
  types: CoverageType[];
  max_coverage_amount_cents?: number;
  multipliers: ICoverageMultiplier[];
  default?: IDefaultCoverage;
}

export interface ICoverageOptionsRaw {
  coverage_options: {
    types: CoverageType[];
    max_coverage_amount_cents: number | null;
    multipliers: ICoverageMultiplierRaw[];
    default: IDefaultCoverageRaw | null;
  };
}

export function rawCoverageOptionsToCoverageOptions(obj: ICoverageOptionsRaw): ICoverageOptions {
  const { types, max_coverage_amount_cents, multipliers: rawMultipliers, default: rawDefault } = obj.coverage_options;

  let defaultCoverage: IDefaultCoverage | null = null;

  if (rawDefault !== null && rawDefault !== undefined) {
    defaultCoverage = {
      type: rawDefault.type,
      label: rawDefault.label,
      value: new Decimal(rawDefault.value)
    };
  }

  const multipliers: ICoverageMultiplier[] = rawMultipliers.map(({ label, value }) => ({
    label,
    value: new Decimal(value)
  }));

  return {
    types,
    max_coverage_amount_cents: max_coverage_amount_cents || undefined,
    multipliers,
    default: defaultCoverage || undefined
  };
}

// Note: 'LoggedOut' is a fake type that only exists in the react app
// this was added because some of our components are loading before they
// maybe should be and we can end up with an empty user type when it
// should neve be null when we need it. This will be revisited soon with
// discussions around access management and roles. GMR May 2021
export type userTypes =
  | 'Tenant'
  | 'ListingAgent'
  | 'PropertyManagerAdmin'
  | 'PropertyManager'
  | 'ThirdPartyAdministrator'
  | 'Administrator'
  | 'LoggedOut';

export interface IUser {
  userId: number;
  userType: userTypes;
  portalSessionId: string;
}

export interface IAccountDetails {
  id: number;
  first_name?: string;
  last_name?: string;
  email: string;
  otp_required_for_login: Boolean;
  two_factor_required: Boolean;
  okta_integration: Boolean;
}

export type IAccountDetailsEditPOST = {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
};

export interface ISegmentUser {
  userId: number;
  userType: userTypes;
  portalSessionId: string;
}

export interface IPMAPropertyEntities {
  id: number;
  name: string;
}
export interface IPMAPropertyOwners {
  id: number;
  name: string;
}

export interface IPMAPropertyOwnerDetails {
  id: number;
  actions: IPMAPropertyOwnerDetailsActions;
  active?: boolean;
  default_coverage_amount_cents: number | null;
  default_coverage_type:
    | CoverageTypeEnum.MULTIPLIER
    | CoverageTypeEnum.DOLLAR_AMOUNT
    | CoverageTypeEnum.NO_COVERAGE
    | CoverageTypeEnum.RP_DEPOSIT_CHARGES;
  coverage_multiplier_limits: Array<string> | null;
  days_remaining_to_edit?: number | null;
  integration_partner_id?: string | null;
  integration_type?: string | null;
  integration_status?: string | null;
  max_coverage_amount_cents?: number;
  name: string;
  claim_classification?: string;
  requires_invitation: boolean | null;
  renters_insurance_requirement_attributes?: {
    requires_renters_insurance: boolean | null;
    has_exclusive_agreement: boolean | null;
    liability_coverage?: string | null;
    id?: number;
  };
  deposit_accounts_enabled: boolean;
  logo: any;
  guarantor_coverage_capable: boolean;
}

export interface IPMAPropertyOwnerDetailsActions {
  edit: boolean;
  delete: boolean;
  can_update_claim_classification: boolean;
}
export type IPMARentersInsuranceReqsEditPost = {
  requires_renters_insurance: boolean | null;
  has_exclusive_agreement: boolean | null;
};

export type IPMAPropertyOwnerEditPOST = {
  name?: string;
  id: number;
  requires_invitation?: boolean | null;
  default_coverage_type?:
    | CoverageTypeEnum.MULTIPLIER
    | CoverageTypeEnum.DOLLAR_AMOUNT
    | CoverageTypeEnum.NO_COVERAGE
    | CoverageTypeEnum.RP_DEPOSIT_CHARGES;
  renters_insurance_requirement_attributes?: IPMARentersInsuranceReqsEditPost;
  claim_classification?: string;
  deposit_accounts_enabled?: boolean;
  guarantor_coverage_capable?: boolean;
};

export interface IPMAPropertyEntityDetails {
  name: string;
  mailing_address: string;
  phone: string;
  email: string;
  id: number;
  can_edit: boolean;
  days_remaining_to_edit: number;
}

export interface IPMAPropertyEntityDocuments {
  policy_documents: Array<IPMAPropertyEntityDocumentInfo>;
}

interface IPMAPropertyEntityDocumentInfo {
  document_types: Array<string>;
  state: string;
  document_id: number;
  created_or_expired_at: string;
  expired: boolean;
  carrier: string;
  policy_number: string;
}

export type IFileData = {
  filename: string;
  url: string;
  download_url: string;
  blob_id?: string;
  attached_to?: string;
};
export interface IPMAPropertyEntityEditPost {
  name: string;
  mailing_address: string;
  phone: string;
  email: string;
  id: number;
}

export interface ICreateClaimParams {
  attachments: string;
  id?: number;
  claim: {
    insurance_policy_id: number | '';
    claim_type: string;
    loss_of_rent_line_items_attributes?: ICreateClaimLossOfRentInfo[];
    payment_option_selected: string;
    bank_account_attributes?: IACHInfo | IACHBankID;
    mailing_address_attributes?: ICheckingInfo;
    amount_cents?: number;
    damaged_room?: string;
    date_of_loss?: string;
    subcategory_2?: string[];
    account_confirmed?: boolean;
    tenant_notified_of_rent_arrears: boolean;
  };
}

interface ICreateClaimLossOfRentInfo {
  arrears_amount_cents: number;
  date_of_loss: string;
  id?: number;
  late_fees_cents: number;
  loss_of_rent_type: number | string;
  other_fees_cents: number;
}

export enum FormMode {
  CREATE = 'create',
  EDIT = 'edit'
}

// Enum to represent string booleans, used for radio buttons
export enum BoolString {
  TRUE = 'true',
  FALSE = 'false'
}

// Converts BoolString to boolean
export const BoolStringToBool = (value: BoolString) => {
  if (value === BoolString.TRUE) return true;
  if (value === BoolString.FALSE) return false;
  return value;
};
//ClaimDetails/ClaimCardDetails
export interface IPolicyCardHeaderProps {
  policy: IPolicyAttributesInClaim;
}
export type IProgressBarStyleProps = {
  remainingPercentOfCoverage: number;
} & JSX.IntrinsicElements['span'];

export type IRemainingCoverageAmountStyleProps = {
  remainingCoverage: number;
} & JSX.IntrinsicElements['span'];

export type IRemainingCoverageWrapperStyleProps = {
  remainingCoverage: number;
};

export type IClaimAmountProps = {
  amount_cents: number;
};

export type IClaimAdjustedAmountProps = {
  adjusted_amount_cents: number;
};
export type IPaidClaimAmountProps = {
  total_amount_paid_cents: number;
};

export type EditActionButtonProps = {
  showWithdraw: boolean;
  showUpdateClaimAmount: boolean;
};
export type WithdrawActionButtonProps = {
  showEdit: boolean;
};
export type AttachmentListProps = {
  isModal?: boolean;
};
export type AttachmentFileItemProps = {
  isModalOpen: boolean;
  isSelected?: boolean;
};

export type AttachmentFileNameProps = {
  isSelected?: boolean;
};

export type UpdateClaimAmountButtonProps = {
  showUpdateClaimAmount: boolean;
  showEdit: boolean;
};
export interface IPropertyManagerAdmin {
  id: number;
  email: string;
}

export interface IPropertySearchResult {
  address_city: string;
  address_line_one: string;
  address_state: string;
  address_zip: string;
  building_name: string;
  full_address: string;
  has_bank_account?: boolean;
  id: number;
  user_assigned?: boolean;
  property_manager_admins?: IPropertyManagerAdmin[];
  cash_deposit_enabled?: boolean;
  guarantor_coverage_enabled?: boolean;
}

export interface IPropertyUnitSearchResult {
  address_city: string;
  address_line_one: string;
  address_line_two: string;
  address_state: string;
  address_zip: string;
  building_name: string;
  full_address: string;
  id: number;
}

export type IClaimNotePOST = {
  description: string;
  attachments: string[];
  claimsId: number;
  public: boolean;
};

export type INotesAttachment = {
  blob_id: string;
  filename: string;
  url: string;
};
export type INotesCreatedBy = {
  full_name: string;
  email: string;
};

export type IClaimsNote = {
  id: number;
  description: string;
  created_by: INotesCreatedBy;
  created_at: string;
  attachments: INotesAttachment[];
};

export type IClaimAttachment = {
  blob_id: string;
  filename: string;
  url: string;
  attached_to: string;
  download_url: string;
};

export interface IClaimAttachmentFilterOptions {
  data: IClaimAttachment[];
  meta: Required<Omit<IPaginatedMeta, 'count'>>;
}

export interface IClaimAttachmentQueryParams {
  option?: string;
  page?: number;
  per?: number;
}

interface ISharedPartnerIntegration {
  actions: IintegrationActions;
  auto_invite_enabled: boolean;
  enabled: boolean;
  enabled_partner_rule_set: boolean;
  enabled_custom_rule_set: boolean;
  integration_status?: string | null;
  integration_validation: IintegrationValidation;
  name: string;
  base_uri: string;
  property_owner_id: number;
  rule_set: string;
  type: string;
  uuid: string;
  ledger_sync_enabled: boolean;
  ledger_sync_create_charge: boolean;
  ledger_sync_tagged_transactions: boolean;
  deposit_charge_code?: string;
  native_enrollment_enabled: boolean;
  show_sdi_coverage_amount: boolean;
  health_check_enabled: boolean;
  renter_url: string;
}

export interface IYardiChargeCode {
  id: number;
  code: string;
  description: string;
  collected: boolean;
}

export interface IYardiIntegrationPartnerableAttributes {
  charge_codes_attributes: Array<IYardiChargeCode>;
  charge_code_availability_warning?: string;
}

export interface IYardiIntegration extends ISharedPartnerIntegration {
  database: string;
  ils_entity: string;
  ils_username: string;
  ils_password: string;
  password: string;
  server_name: string;
  skip_applicant_enabled: boolean;
  skip_future_enabled: boolean;
  update_enabled: boolean;
  username: string;
  invite_resident_statuses: Array<string>;
  invite_resident_credit_statuses: Array<string>;
  integration_partnerable_attributes: IYardiIntegrationPartnerableAttributes;
  allow_writes: boolean;
  single_family_only: boolean;
  cash_deposit_push_enabled: boolean;
}

export interface IRealPageIntegration extends ISharedPartnerIntegration {
  invite_immediately: boolean;
  conditional_only_additional_deposit_enabled: boolean;
  deposit_coverage_enabled: boolean;
  pet_deposit_enabled: boolean;
  pmc_id: string;
  property_coverage_enabled: boolean;
  renewal_invite_enabled: boolean;
  update_enabled: boolean;
  unit_name_format: string;
  deposit_payment_code: string;
  cash_deposit_push_enabled: boolean;
}

interface IintegrationValidation {
  credential_check_status: PartnerValidationStatus;
  properties_match_status: PartnerValidationStatus;
  unit_match_status: PartnerValidationStatus;
}

interface IintegrationActions {
  edit_integration: boolean;
  validate_integration: boolean;
}

export interface ICoverageRule {
  coverage_option_name: string;
  coverage_type: string;
  coverage_value: number;
  same_cash_deposit: boolean;
  cash_deposit_type: string;
  cash_deposit_coverage_value: number;
  id?: number;
  cash_deposit_rule_id?: number;
  condition_type?: string;
}

export interface ICoverageRuleset {
  coverage_rules: ICoverageRule[];
  type: string; // integration_partner || property
  coverageable_ids: string[];
  default_coverageable_ids?: string[];
  id?: number;
  default?: boolean;
  deposit_threshold_cents?: number;
}

export interface IRentersInsurancePolicyDetails {
  id: number;
  user_id: number;
  ins_number: string;
  coverage_start_date: string;
  coverage_end_date: string;
  confirmed_at: string;
  partner_security_token: string;
  status: string;
  in_full_quote_cents: number;
  liability: number;
  contents: number;
  claim_url: string;
  policy_wording_url: string;
  download_policy_document_url: string;
  underwriter: string;
  credit_card_last_4: string;
  status_for_ri_card: string;
}

export interface ICustomCoverageRuleset extends ICoverageRuleset {
  name: string;
  properties: IPropertySearchResult[];
  uuid: string;
}

export interface ICoverageRulesetPost extends ICoverageRuleset {
  name?: string;
}

export interface IInviteCriteria {
  allowed_screening_results: string[];
  allowed_credit_check_statuses: string[];
  use_renter_approval_date: boolean;
  id?: string;
}

export interface ICoverageRulesetPostNew extends ICoverageRulesetPost {
  invite_criteria?: IInviteCriteria;
  properties?: IPropertySearchResult[];
  uuid?: string;
}

export interface IRulesetError {
  coverage_rules: string;
}

export interface IPropertyOwnerSearchResult {
  id: number;
  name: string;
  active: boolean;
  default_coverage_type: string;
  coverage_multiplier_limits: string[];
  default_coverage_amount_cents: string;
  max_coverage_amount_cents: string;
  integration_type: string;
  integration_status?: PartnerValidationStatus | null;
  claim_classification?: PropertyOwnerClaimClassification | null;
}

export type PartnerValidationStatus =
  | PartnerIntegrationStatusEnum.NOT_VALIDATED
  | PartnerIntegrationStatusEnum.ERROR
  | PartnerIntegrationStatusEnum.WAITING
  | PartnerIntegrationStatusEnum.IN_PROGRESS
  | PartnerIntegrationStatusEnum.SUCCESS
  | PartnerIntegrationStatusEnum.FAILED;

export enum PartnerIntegrationStatusEnum {
  NOT_VALIDATED = 'not_validated',
  ERROR = 'error',
  WAITING = 'waiting',
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'success',
  FAILED = 'failed'
}

export enum PropertyOwnerClaimClassification {
  VIP = 'vip',
  SIU = 'siu'
}

export interface IRentersInsuranceLiabilityCoverages {
  liability_coverages: IRentersInsuranceLiabilityCoverage[];
}

export interface IRentersInsuranceLiabilityCoverage {
  label: string;
  value: number;
}

export interface ISubrogationPayment {
  creator: string;
  id: number;
  net_recovery_cents: number;
  paid_at: string;
  processing_fee_cents: number;
  recovered_amount_cents: number;
}

export interface ISubrogationPaymentTable {
  data: ISubrogationPayment[];
  meta: {
    total_count: number;
    next_page: number;
    prev_page: number;
    page: number;
    per: number;
  };
}

export interface IPayment {
  paid_at: string | Date;
  amount_paid_cents: number;
}
export interface IClaimPayout {
  amount_paid_cents: number;
  paid_at: string | Date;
  id: number;
}

export interface IClaimChangelog {
  audits: IClaimAudit[];
}

export interface IClaimAudit {
  status: string;
  user?: string;
  createdAt: Date;
}
