/** @jsx jsx */
import { useRef, useState, useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import { FONTS, Loading, PlusIcon, CloseIcon, SquareButton, PALETTE, Button } from '@sayrhino/rhino-shared-js';
import * as Toggle from '@radix-ui/react-toggle';
import useClaims from 'api/v2/useClaims';
import { flatMap, some } from 'lodash';
import { useQueryClient } from 'react-query';
import { NavLink as Link, useLocation, useMatch } from 'react-router-dom';
import emptyImage from '../../../../assets/images/empty-hole.svg';
import {
  claimPropertyFilterOptionsKey,
  getClaimPropertyFilterOptions
} from '../../../api/v2/useClaimPropertyFilterOptions';
import { claimStaticFilterOptionsKey, getClaimStaticFilterOptions } from '../../../api/v2/useClaimStaticFilterOptions';
import useIntersectionObserver from '../../../hooks/v2/useIntersectionObserver';
import { ClaimCard, convertClaimUpdateToCardProps } from './ClaimCard';
import CardLink from './CardLink';
import FilterLink from './Filters/FilterLink';
import { useFilterContext } from './utils/filters';
import { urlWithReferrer } from './utils';
import { useSegmentTrackOnLoad } from './utils/segmentTracker';
import { useUserRole, useIsClaimAdmin } from './utils/userRole';
import { useClaimContext } from './utils/claimBatchAssign';
import useUpdateClaimStatus from 'api/v2/updateClaimStatus';
import { useNavigate } from 'react-router-dom';
import { ClaimStatus } from './interfaces';
import useToast, { TOAST_STATUS } from './toast/use-toast';
import { AxiosError } from 'axios';
import { claimsFilterCount } from './utils/generateMaskedStatus';
import { csrfToken } from 'utils/document';
import { guidedClaimsIsEnabled, enableGuidedClaims, disableGuidedClaims } from 'api/v2/useGuidedClaimsEnablement';

const claimsPath = '/admin/claims';
const filtersPath = '/admin/claims/filters';
const batchAssignPath = '/admin/claims/batch_assign';

const prefetchFilterOptions = (debouncedFilters) => {
  const cache = useQueryClient();
  const params = { filter: { states: debouncedFilters.states }, page: 1 };

  cache.prefetchQuery([claimPropertyFilterOptionsKey, params], () => getClaimPropertyFilterOptions(params));
  cache.prefetchQuery([claimStaticFilterOptionsKey], getClaimStaticFilterOptions);
};

const guidedClaimsOfferContainerCSS = css({
  display: 'flex',
  padding: '16px',
  width: "100%",
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  border: '1px solid var(--Neutral-50, #ADB4B8)',
  background: 'var(--Neutral-10, #F5F6F7)',
  fontFamily: "Maison Neue",
  marginBottom: '24px'
});

const guidedClaimsInputCSS = css({
  display: 'none',
});

const toggleButtonCSS = css({
  border: 'none',
  background: 'var(--Neutral-10, #F5F6F7)',
});

const buttonLinkCSS = css({
  display: 'flex',
  alignItems: 'center',
  padding: '3px 12px 3px 20px',
  borderRadius: '24px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  color: PALETTE.neutralDark,
  border: '1px solid',
  borderColor: PALETTE.neutralDark,
  position: 'relative',
  '&:hover': {
    backgroundColor: PALETTE.neutralDark,
    color: PALETTE.neutralLight,
    svg: {
      color: PALETTE.neutralLight
    }
  },
  '&:disabled': {
    color: PALETTE.neutral55,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'not-allowed'
  },
  '&:focus::after': {
    content: "' '",
    position: 'absolute',
    zIndex: 1,
    top: '-4px',
    left: '-4.65px',
    right: '-4.65px',
    bottom: '-4px',
    border: '2px solid #6B93FF',
    borderRadius: '35.2075px'
  }
});

const closeButtonCSS = css({
  alignItems: 'center',
  backgroundColor: PALETTE.neutralDark,
  border: 'none',
  borderRadius: '20px',
  display: 'flex',
  height: '2rem',
  justifyContent: 'center',
  width: '2rem',
  color: PALETTE.neutralLight,
  cursor: 'pointer'
});

const addIconCSS = css({
  paddingLeft: 9,
  color: PALETTE.neutralDark
});

const batchModeInactiveButton = {
  border: '1px solid #004DBF',
  backgroundColor: '#004DBF',
  color: 'white'
};

const batchModeActiveButton = {
  border: `1px solid ${PALETTE.neutral88}`,
  backgroundColor: 'white',
  color: 'black'
};

export const ClaimList = () => {
  const { debouncedFilters, filtersCount, selectedFilters } = useFilterContext();
  const filtersActive = Boolean(useMatch(filtersPath));
  const claimsResponse = useClaims(debouncedFilters);
  const { hasNextPage, fetchNextPage, isSuccess, isLoading, isFetchingNextPage, data } = claimsResponse;
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [isToggleOn, setIsToggleOn] = useState(false);
  const claimsData = flatMap(data?.pages, (d) => d.data || []);
  const hasClaims = isSuccess && claimsData.length > 0;
  const location = useLocation();
  const userRole = useUserRole();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { isAdmin, isPartner, isPropertyManager, isPropertyManagerAdmin, isThirdPartyAdmin } = userRole;
  const { setBatchAssignMode, inBatchAssignMode, setBatchSelectedClaims, batchedClaims, setSelectedAnalyst } =
    useClaimContext();

  const canFilter = isAdmin || isPropertyManager || isPropertyManagerAdmin || isThirdPartyAdmin;
  const canFileClaim = isPropertyManager || isPropertyManagerAdmin;
  const isClaimAdmin = useIsClaimAdmin();
  const queryClient = useQueryClient();
  const { mutate: changeClaimStatus } = useUpdateClaimStatus();
  const filterCount = isPartner ? claimsFilterCount(selectedFilters) : filtersCount;
  const submitGuidedClaimsToggle = async (e, value) => {
    e.preventDefault();

    try {
      if(value) {
        await enableGuidedClaims();
        setIsToggleOn(true);
      }else {
        await disableGuidedClaims();
        setIsToggleOn(false);
      }
    } catch (error) {
      addToast('Something went wrong', 'error', TOAST_STATUS.ERROR);
    }
  }

  const rememberGuidedClaimsEnablement = () => {
    useEffect(() => {
      const fetchGuidedClaimsStatus = async () => {
        try {
          const isEnabled = await guidedClaimsIsEnabled();
          setIsToggleOn(isEnabled);
        } catch (error) {
          console.error('Error fetching guided claims status:', error);
        }
      };

      fetchGuidedClaimsStatus();
    }, []);
  }
  rememberGuidedClaimsEnablement();

  const claims =
    isSuccess &&
    claimsData.map((claim) => {
      const handleClaimClick = () => {
        if (isThirdPartyAdmin && claim.status === ClaimStatus.NEW) {
          changeClaimStatus(
            { id: claim.id, selectedStatus: { label: 'Processing', value: 'processing' } },
            {
              onSuccess: (response) => {
                addToast(`Claim successfully ${response.data.status}`, '', TOAST_STATUS.SUCCESS);
                queryClient.invalidateQueries('claims');
                queryClient.invalidateQueries(['claim', claim.id]);
                navigate(`${claimsPath}/${claim.id}`);
              },
              onError: (error: AxiosError) => {
                if (error.response?.status === 401) {
                  addToast(error.response.statusText, 'neutral_warning', TOAST_STATUS.ERROR);
                } else {
                  addToast('Something went wrong', 'neutral_warning', TOAST_STATUS.ERROR);
                }
              }
            }
          );
        } else {
          navigate(`${claimsPath}/${claim.id}`);
        }
      };

      return inBatchAssignMode ? (
        <Toggle.Root
          css={[
            {
              padding: 0,
              margin: 0,
              textAlign: 'inherit',
              width: 484,
              appearance: 'none',
              backgroundColor: 'transparent',
              border: '2px solid transparent',
              borderRadius: 8,
              marginBottom: 10,
              marginTop: -2,
              marginLeft: -2,

              '&:focus': {
                outline: 'none'
              },

              '&:focus > div': {
                boxShadow: `0px 4px 16px 0px ${PALETTE.neutral12}`
              },

              '&[data-state=on]': {
                border: `2px solid ${PALETTE.blew}`
              }
            }
          ]}
          onPressedChange={() =>
            setBatchSelectedClaims({
              administrator: claim.administrator,
              claim_type: claim.claim_type,
              id: claim.id,
              property_address_line_one: claim.property_address_line_one,
              renter_full_name: claim.renter_full_name
            })
          }
          pressed={some(batchedClaims, ['id', claim.id])}
          key={`${claim.type}-${claim.id}`}
        >
          <ClaimCard {...convertClaimUpdateToCardProps(claim, userRole)} />
        </Toggle.Root>
      ) : (
        <CardLink to={`${claimsPath}/${claim.id}`} key={`${claim.type}-${claim.id}`} onClick={handleClaimClick}>
          <ClaimCard {...convertClaimUpdateToCardProps(claim, userRole)} />
        </CardLink>
      );
    });
  const empty = isSuccess && (
    <div css={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <img src={emptyImage} css={{ display: 'block', margin: '5.75rem auto 2.5rem' }} alt="Empty" />
      <div css={[FONTS.h3, { width: '23.75rem', textAlign: 'center' }]}>
        Looks like nothing matches your filter result. Try a different filter combination.
      </div>
    </div>
  );
  const loadMoreDiv = isFetchingNextPage && <Loading />;
  const loadMoreButtonOrDiv = () => {
    if (hasNextPage && !isFetchingNextPage) {
      return (
        <Button
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || Boolean(isFetchingNextPage)}
          variant="secondary"
          css={{ margin: 'auto', marginTop: '40px' }}
        >
          Load More Tasks
        </Button>
      );
    }
    return loadMoreDiv;
  };
  const nothingMoreDiv = claims.length > 1 && 'Nothing more to load';
  const loading = (
    <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Loading />
    </div>
  );

  useIntersectionObserver({
    root: null,
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isFetchingNextPage
  });
  prefetchFilterOptions(debouncedFilters);

  useSegmentTrackOnLoad('Claims Viewed');

  const toggleBatchAssign = () => {
    if (inBatchAssignMode) {
      setSelectedAnalyst({ analystID: undefined, analystName: '' });
      setBatchSelectedClaims(undefined);
    }
    setBatchAssignMode();
  };

  const guidedClaimsOnOffer = (window as any).App?.featureFlags?.guidedClaimsOnOffer;

  const ToggleButtonOnSVG = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="24" viewBox="0 0 40 24" fill="none">
        <rect width="40" height="24" rx="12" fill="#6A3BF5"/>
        <rect x="20" y="4" width="16" height="16" rx="8" fill="white"/>
      </svg>
    )
  };

  const ToggleButtonOffSVG = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="24" viewBox="0 0 40 24" fill="none">
        <rect x="0.5" y="0.5" width="39" height="23" rx="11.5" fill="white" stroke="#ADB4B8"/>
        <rect x="4" y="4" width="16" height="16" rx="8" fill="#ADB4B8"/>
      </svg>
    )
  };


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '480px',
        margin: 'auto',
        paddingTop: '1.875rem'
      }}
    >

      {canFileClaim && guidedClaimsOnOffer && (
          <div css={guidedClaimsOfferContainerCSS} >
            <p>Opt-in to the new claims experience</p>
            <form>
              <input type="hidden" name="authenticity_token" value={csrfToken()} />
              {isToggleOn ?
                <button type='submit' css={toggleButtonCSS} onClick={(e)=> {submitGuidedClaimsToggle(e, false)}}>
                  <ToggleButtonOnSVG />
                </button>
                :
                <button type='submit' css={toggleButtonCSS} onClick={(e)=> {submitGuidedClaimsToggle(e, true)}}>
                  <ToggleButtonOffSVG />
                </button>
              }
            </form>
          </div>
      )}

      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '1.875rem' }}
      >
        <h2 css={[FONTS.h3]}>{inBatchAssignMode ? 'Assign Claims' : 'Claims'}</h2>

        {canFileClaim && !inBatchAssignMode && (
          guidedClaimsOnOffer ?
            (isToggleOn ?
              <Link to='' css={[buttonLinkCSS]} onClick={(e) => { e.preventDefault(); window.location.href = '/admin/claim_drafts'; }}>
                File a Claim <PlusIcon css={addIconCSS} />
              </Link>
              :
              <Link to='/admin/claims/new' css={[buttonLinkCSS]}>
                File a Claim <PlusIcon css={addIconCSS} />
              </Link>
            )
          :
            <Link to="/admin/claims/new" css={[buttonLinkCSS]}>
              File a Claim <PlusIcon css={addIconCSS} />
            </Link>
        )}
        {inBatchAssignMode && (
          <Link
            css={closeButtonCSS}
            to={urlWithReferrer(claimsPath)}
            onClick={() => toggleBatchAssign()}
            aria-label="collapse"
          >
            <CloseIcon height="16px" width="16px" />
          </Link>
        )}
      </div>
      {(isClaimAdmin || canFilter) && (
        <div css={{ display: 'flex', marginBottom: '1.5rem' }}>
          {isClaimAdmin && (
            <Link
              to={urlWithReferrer(inBatchAssignMode ? claimsPath : batchAssignPath, location.pathname)}
              onClick={() => toggleBatchAssign()}
            >
              <SquareButton
                variant={inBatchAssignMode ? 'primary' : 'secondary'}
                style={inBatchAssignMode ? batchModeActiveButton : batchModeInactiveButton}
              >
                Batch Assign
              </SquareButton>
            </Link>
          )}
          {canFilter && (
            <span css={{ marginLeft: 'auto' }}>
              <FilterLink
                filtersActive={filtersActive}
                filtersCount={filterCount}
                id="claims-filter-button"
                to={urlWithReferrer(
                  filtersActive ? (inBatchAssignMode ? batchAssignPath : claimsPath) : filtersPath,
                  location.pathname
                )}
              />
            </span>
          )}
        </div>
      )}
      {hasClaims ? claims : empty}
      {isLoading ? loading : null}
      <div
        ref={loadMoreRef}
        css={[
          FONTS.h4,
          {
            display: hasClaims ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '18px'
          }
        ]}
      >
        {hasNextPage ? loadMoreButtonOrDiv() : nothingMoreDiv}
      </div>
    </div>
  );
};

export default ClaimList;
