import createBankAccount from 'api/v2/createBankAccount';
import { updateBankAccount } from 'api/v2/updateBankAccount';
import useBankAccountDetails from 'api/v2/useBankAccountDetails';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useToast from '../../toast/use-toast';
import { IBankAccountInfo } from '../types';
import { FormMode } from '../../interfaces';

const defaultBankAccount = {
  bank_name: '',
  first_name_on_account: '',
  last_name_on_account: '',
  nickname: '',
  ach: '',
  account_type: '',
  internal_account_type: 'us_domestic',
  state: 'in_use',
  contact_phone_number: '',
  account_number: '',
  bank_account_uid: '',
  recipient_address_city: '',
  recipient_address_state: '',
  recipient_address_street: '',
  recipient_post_code: '',
  recipient_country: '',
  account_uid: ''
};

const bankAccountErrors = {
  bank_name: '',
  first_name_on_account: '',
  last_name_on_account: '',
  nickname: '',
  ach: '',
  bank_account_uid: '',
  account_type: '',
  contact_phone_number: '',
  account_number: '',
  recipient_address_city: '',
  recipient_address_state: '',
  recipient_address_street: '',
  recipient_post_code: '',
  recipient_country: '',
  account_uid: ''
};

const useBankAccountForm = () => {
  const { accountId, accountType, ...props } = useParams();
  const [searchParams] = useSearchParams();
  const formMode = accountId ? FormMode.EDIT : FormMode.CREATE;

  const { data: bankAccount, isLoading: isBankAccountLoading } =
    useBankAccountDetails(accountId, searchParams.get('accountType')) ?? {};
  const navigate = useNavigate();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (bankAccount) {
      setAccount(bankAccount);
    }
  }, [bankAccount]);

  const [account, setAccount] = useState<Omit<IBankAccountInfo, 'bank_account_uid'>>(bankAccount ?? defaultBankAccount);

  const [errors, setErrors] =
    useState<Omit<IBankAccountInfo, 'internal_account_type' | 'bank_account_uid'>>(bankAccountErrors);
  const { mutate: createBankAccountMutation } = useMutation(createBankAccount, {});
  const { mutate: updateBankAccountMutation, isLoading: isUpdateAccountLoading } = useMutation(updateBankAccount, {});

  const create = (params: Omit<IBankAccountInfo, 'bank_account_uid'>) => {
    createBankAccountMutation(
      {
        bank_name: params.bank_name,
        account_number: params.account_number,
        ach: params.ach,
        first_name_on_account: params.first_name_on_account,
        last_name_on_account: params.last_name_on_account,
        account_type: params.account_type,
        internal_account_type: params.internal_account_type ?? 'us_domestic',
        contact_phone_number: params.contact_phone_number,
        nickname: params.nickname,
        ...(params.internal_account_type === 'international' && {
          bic_swift_code: params.bic_swift_code,
          recipient_country: params.recipient_country,
          recipient_address_street: params.recipient_address_street,
          recipient_address_city: params.recipient_address_city,
          recipient_address_state: params.recipient_address_state,
          recipient_postal_code: params.recipient_postal_code
        })
      },
      {
        onSuccess: (res) => {
          const path = generatePath('/admin/funds');
          queryClient.invalidateQueries('adminDepositFunds');
          navigate(`${path}`);
          addToast('Bank Account Added');
        },
        onError: (e: AxiosError) => {
          console.log({ e });
          handleError(e);
        }
      }
    );
  };
  const update = (params: Omit<IBankAccountInfo, 'bank_account_uid'>) => {
    updateBankAccountMutation(
      {
        ...params
      },
      {
        onSuccess: (res: AxiosResponse) => {
          const path = generatePath('/admin/funds');
          queryClient.invalidateQueries('adminDepositFunds');
          navigate(`${path}`);
          addToast('Bank Account updated');
        },
        onError: (e: AxiosError) => {
          handleError(e);
        }
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formMode === FormMode.CREATE) {
      create(account);
    } else {
      update(account);
    }
  };

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 422) {
      const data = error.response?.data;
      const responseErrors = data.errors;
      setErrors(JSON.parse(responseErrors));
    } else {
      navigate(-1);
      addToast('Something went wrong', 'error');
    }
  };

  return {
    account,
    setAccount,
    create,
    handleSubmit,
    handleError,
    errors,
    update,
    formMode,
    isLoading: isBankAccountLoading || isUpdateAccountLoading
  };
};

export { useBankAccountForm };
