import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';
import { useMutation, MutationFunction } from 'react-query';
import { IBankAccountInfo } from 'components/v2/App/DepositFunds/types';

interface IUpdateBankAccountResponse {
  errors?: string[];
}

export const updateBankAccount: MutationFunction<AxiosResponse<IUpdateBankAccountResponse>, Omit<IBankAccountInfo, "bank_account_uid" | "account_uid">> = async (
  bankAccount: Partial<IBankAccountInfo>
): Promise<AxiosResponse<IUpdateBankAccountResponse>> => {
  const authenticity_token = csrfToken();

  const response: AxiosResponse<IUpdateBankAccountResponse> = await axios.patch(
    `/admin/funds/accounts/${bankAccount.bank_account_uid}`,
    {
      bank_account: {
        bank_name: bankAccount.bank_name,
        account_number: bankAccount.account_number,
        account_type: bankAccount.account_type,
        contact_phone_number: bankAccount.contact_phone_number,
        first_name_on_account: bankAccount.first_name_on_account,
        last_name_on_account: bankAccount.last_name_on_account,
        nickname: bankAccount.nickname,
        ach: bankAccount.account_number,
        internal_account_type: bankAccount.internal_account_type,
        ...(bankAccount.internal_account_type === 'international' && {
          bic_swift_code: bankAccount.bic_swift_code,
          recipient_country: bankAccount.recipient_country,
          recipient_address_street: bankAccount.recipient_address_street,
          recipient_address_city: bankAccount.recipient_address_city,
          recipient_address_state: bankAccount.recipient_address_state,
          recipient_postal_code: bankAccount.recipient_postal_code
        })
      }
    },
    {
      headers: {
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};


