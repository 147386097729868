/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Switch, Radio, Checkbox, CloseIcon, Typeahead, Loading, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import SwitchSelector from './coverageSelector';
import { PropertyOwnerSelect, PolicyHolderSelect, MultiplierPropertySelect, IntegrationSelect } from './select';
import ErrorNotification from './ErrorNotification';
import { UserContext } from '.';
import { PropertyManagerTypeahead } from './PropertyManagerTypeahead';
import { ListingAgentTypeahead } from './ListingAgentTypeahead';
import GooglePlaceIdTypeahead from './GooglePlaceIdTypeahead';
import { Close, Header, LargeHeader, Page } from './components';
import { SegmentUser } from '../../utils/segmentTracker';
import TooltipInfoIcon from '../../TooltipInfoIcon';
import { IStateOption } from '../../interfaces';
import { ToastContextProvider } from '../../toast/toast-context';
import useSinglePropertyUploadLogic from './SPULogic';
import { Coverage, property_types, CashDepositStatus, UsesPartnerEnrollmentStatus } from './types';
import {
  Button,
  CreateableComponents,
  FormView,
  RadioRoot,
  StyledLine,
  SelectorWrapper,
  Text,
  TextInput,
  Title,
  Tooltip,
  TooltipContainer,
  TooltipIconContainer,
  TooltipText,
  CheckboxTooltipContainer
} from './Styles';
import { LoadingWrapper } from '../../PropertyOwners/Styled';
import { sanitizeCurrencyInput } from '../../utils';
import useFeatureFlags from 'hooks/v2/useFeatureFlags';

const Form: React.FC = () => {
  const { formMeta } = useSinglePropertyUploadLogic();

  if (!formMeta.isFormLoading && formMeta.isFormSuccess) {
    return <PropertyEditForm />;
  }
  return (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

const PropertyEditForm: React.FC = () => {
  const {
    property,
    handleChange,
    formErrors,
    setGooglePlaceId,
    setListingAgents,
    setPropertyManagers,
    setProperty,
    setValidationError,
    googlePlaceId,
    propertyManagers,
    listingAgents,
    user,
    statesData,
    errors,
    isLoading,
    onSubmit,
    getError,
    handleKeyDown,
    state,
    handleUnitChange,
    handleUnitInputChange,
    clearMultiPropertyInput,
    convertToDecimalAndFormatUSD,
    cashDeposit,
    setCashDeposit,
    usesPartnerEnrollment,
    setUsesPartnerEnrollment,
    onSyncProspects,
    rentersInsuranceEnabled,
    setRentersInsuranceEnabled
  } = useSinglePropertyUploadLogic();

  const { featureFlags } = useFeatureFlags();

  const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <FormView>
      <Title>Property information</Title>
      <StyledLine />
      <TextInput
        id="name"
        label="Property Name"
        data-cy="singlePropertyName"
        value={property.building_name ?? ''}
        onChange={(event) => handleChange(event, 'building_name')}
        error={formErrors('building_name')}
        subtext={getError('building_name') ?? ''}
      />
      <PropertyOwnerSelect
        label="Property Management Company"
        data-cy="singlePropertyOwner"
        value={property.property_owner}
        onChange={(property_owner) => {
          setProperty((prev) => ({
            ...prev,
            property_owner
          }));
          setValidationError((prev) => ({ ...prev, property_owner_name: undefined }));
        }}
        error={getError('property_owner_name')}
      />
      <PolicyHolderSelect
        label="Property Legal Entity"
        data-cy="singlePropertyLegalEntity"
        value={property.policy_holder}
        onChange={(policy_holder) => {
          setProperty((prev) => ({
            ...prev,
            policy_holder
          }));
          setValidationError((prev) => ({ ...prev, policy_holder_name: undefined }));
        }}
        error={getError('policy_holder_name')}
      />
      <TextInput
        id="street"
        label="Street address"
        data-cy="singlePropertyStreet"
        value={property.address_line_one ?? undefined}
        onChange={(event) => handleChange(event, 'address_line_one')}
        error={formErrors('address_line_one')}
        subtext={getError('address_line_one') ?? ''}
      />
      <TextInput
        id="city"
        label="City"
        data-cy="singlePropertyCity"
        value={property.address_city ?? ''}
        onChange={(event) => handleChange(event, 'address_city')}
        error={formErrors('address_city')}
        subtext={getError('address_city') ?? ''}
      />
      <Typeahead
        id="state"
        label="State"
        isSearchable={true}
        data-cy="singlePropertyState"
        placeholder={property.address_state === '' ? '-' : property.address_state}
        defaultValue={property.address_state ?? '-'}
        onChange={(selection: IStateOption) => handleChange(selection, 'address_state')}
        options={statesData?.states}
        subtext={getError('address_state') ?? ''}
        error={formErrors('address_state')}
      />
      <TextInput
        id="zip"
        label="ZIP Code"
        data-cy="singlePropertyZip"
        value={property.address_zip ?? ''}
        onChange={(event) => handleChange(event, 'address_zip')}
        error={formErrors('address_zip')}
        subtext={getError('address_zip') ?? ''}
      />

      {user.isAdmin && (
        <div>
          <GooglePlaceIdTypeahead
            id="check mailing address line one"
            label="Google Place"
            googlePlaceId={googlePlaceId ?? ''}
            setGooglePlaceId={setGooglePlaceId}
          />
        </div>
      )}
      {user.isAdmin && (
        <TextInput
          id="slug"
          label="Slug"
          data-cy="singlePropertySlug"
          value={property.slug ?? ''}
          onChange={(event) => handleChange(event, 'slug')}
          error={formErrors('slug')}
          subtext={getError('slug') ?? ''}
        />
      )}
      <PropertyManagerTypeahead
        onChange={setPropertyManagers}
        selectedPM={propertyManagers}
        id="property-manager-typeahead"
      />
      <ListingAgentTypeahead onChange={setListingAgents} selectedPM={listingAgents} id="listing-agents-typeahead" />
      <Title>Property Type</Title>
      <RadioRoot
        name="property_type"
        value={property.property_type}
        onValueChange={(value: property_types) => {
          setProperty((prev) => {
            if (value === property_types.MULTI && prev.property_type === property_types.SINGLE) {
              // sets input back to initial values, prevents "N/A" from getting added
              clearMultiPropertyInput();
            }
            return {
              ...prev,
              property_type: value
            };
          });
        }}
      >
        <Radio.Option data-cy="singlePropertyTypeSingle" value={property_types.SINGLE} label="Single-family home" />
        <Radio.Option data-cy="singlePropertyTypeMulti" value={property_types.MULTI} label="Multi-family home" />
        <Radio.Option data-cy="singlePropertyTypeGarden" value={property_types.GARDEN} label="Garden community" />
        <Radio.Option data-cy="singlePropertyTypeSAGC" value={property_types.SAGC} label="Single-address garden community" />
        <Radio.Option data-cy="singlePropertyTypeStudent" value={property_types.STUDENT} label="Student Housing" />
      </RadioRoot>

      {property?.property_type === property_types.MULTI || property?.property_type === property_types.SAGC ? (
        <div>
          <Title>Property Units</Title>
          <CreatableSelect
            components={CreateableComponents}
            inputValue={state.inputValue}
            isMulti
            onKeyDown={handleKeyDown}
            menuIsOpen={false}
            onChange={handleUnitChange}
            onInputChange={handleUnitInputChange}
            placeholder={property.units ?? 'Type the unit name and press enter...'}
            value={state.value}
            error={errors['units']}
          />
          {formErrors('units') && <ErrorNotification errors={[getError('units')]} />}
        </div>
      ) : (
        <div>
          <Title>Property Units (only for multi-family home or single-address garden community)</Title>
          <TextInput id="unit input" disabled={true} placeholder={'N/A'} />
        </div>
      )}

      {user.isAdmin && (
        <div>
          <Title>Additional Details</Title>
          <Checkbox
            id="Multi_Policy_Holder"
            label="Multi Policy Holder"
            checked={Boolean(property?.multi_policy_holder)}
            onCheckedChange={(value) =>
              setProperty((prev) => ({
                ...prev,
                multi_policy_holder: value
              }))
            }
          />
          {property.property_type === property_types.MULTI && (
            <CheckboxTooltipContainer>
              <Checkbox
                id="Multi_Owner"
                label="Multiple Owners"
                checked={Boolean(property?.multiple_owners)}
                disabled={Boolean(!property?.can_edit_multiple_owners)}
                onCheckedChange={(value) =>
                  setProperty((prev) => ({
                    ...prev,
                    multiple_owners: value
                  }))
                }
              />
              {Boolean(!property?.can_edit_multiple_owners) && (
                <TooltipContainer>
                  <TooltipIconContainer data-tip data-for="multiple-owners">
                    <TooltipInfoIcon />
                  </TooltipIconContainer>
                  <Tooltip id="multiple-owners" type="light" effect="solid" place="right">
                    <TooltipText>
                      Unable to complete this action because multiple properties exist for this address.
                    </TooltipText>
                  </Tooltip>
                </TooltipContainer>
              )}
            </CheckboxTooltipContainer>
          )}
        </div>
      )}
      <Title>Coverage Type</Title>
      <Text>
        Please provide the standard security deposit amount for your property, either as a multiplier or a dollar
        amount.
      </Text>
      <SelectorWrapper>
        <SwitchSelector
          active={property.coverage_details?.type ?? Coverage.MULTIPLIER}
          list={[
            { label: 'Multiplier', value: Coverage.MULTIPLIER },
            { label: 'Dollar amount', value: Coverage.AMOUNT }
          ]}
          id="coverage_type"
          onClick={(value) =>
            setProperty((prev) => ({
              ...prev,
              coverage_details: {
                value: 0,
                type: value
              }
            }))
          }
        />
      </SelectorWrapper>
      <section style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          paddingBottom: '24px'
      }} >
        {property.coverage_details.type === Coverage.AMOUNT && (
          <TextInput
            id="Dollar amount"
            label="Dollar amount"
            data-cy="singlePropertyCoverageDollarAmount"
            placeholder="e.g. $1,500"
            value={convertToDecimalAndFormatUSD(property.coverage_details?.value)}
            onChange={(e) =>
              setProperty((prev) => ({
                ...prev,
                coverage_details: {
                  ...prev?.coverage_details,
                  value: sanitizeCurrencyInput(e)
                }
              }))
            }
            min="0"
            onInput={(event) => handleChange(event, 'coverage_details_dollar_amount')}
            error={formErrors('coverage_details_value')}
            subtext={getError('coverage_details_value')}
          />
        )}
        {property.coverage_details?.type === Coverage.MULTIPLIER && (
          <MultiplierPropertySelect
            id={property.property_owner?.id ?? null}
            data-cy="singlePropertyCoverageMultiplier"
            errorPresent={formErrors('coverage_details_value')}
            subtext={
              getError('coverage_details_value') && getError('coverage_details_value') !== 'must be greater than $100'
                ? getError('coverage_details_value')
                : ''
            }
            value={property.coverage_details}
            label="Multiplier"
            onChange={(mulp) => {
              setProperty((prev) => ({
                ...prev,
                coverage_details: {
                  ...prev?.coverage_details,
                  ...mulp
                }
              }));
              setValidationError((prev) => ({ ...prev, coverage_details_value: undefined }));
            }}
          />
        )}
        {user.isAdmin && (
          <div>
            <IntegrationSelect
              label="Integration Type"
              value={property?.integration_type}
              onChange={(intType) =>
                setProperty((prev) => ({
                  ...prev,
                  integration_type: intType?.value ?? 'no_integration'
                }))
              }
              error={getError('integration_type')}
            />
            <TextInput
              id="integration_code"
              label="Integration Code"
              data-cy="integrationCode"
              value={property?.integration_code}
              onChange={(event) => handleChange(event, 'integration_code')}
              error={formErrors('integration_code')}
              subtext={getError('integration_code') ?? ''}
            />
            <TextInput
              id="renter_cash_deposit_portal_name"
              label='Renter cash deposit portal name (prefixed with "Go to" in the CTA)'
              data-cy="renterCashDepositPortalName"
              value={property?.renter_cash_deposit_portal_name ?? ''}
              onChange={(event) => handleChange(event, 'renter_cash_deposit_portal_name')}
              error={formErrors('renter_cash_deposit_portal_name')}
              subtext={getError('renter_cash_deposit_portal_name') ?? ''}
            />
            <TextInput
              id="renter_cash_deposit_portal_url"
              label="Renter cash deposit portal URL"
              data-cy="renterCashDepositPortalUrl"
              value={property?.renter_cash_deposit_portal_url ?? ''}
              onChange={(event) => handleChange(event, 'renter_cash_deposit_portal_url')}
              error={formErrors('renter_cash_deposit_portal_url')}
              subtext={getError('renter_cash_deposit_portal_url') ?? ''}
            />
            {featureFlags.syncProspectsEnabled &&
              property?.integration_code &&
              property?.integration_type != undefined &&
              ['yardi', 'realpage'].includes(property?.integration_type) && (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                  }}>
                    <h4 css={FONTS.h4}>
                      {capitalizeFirstLetter(property?.integration_type)} Integration
                    </h4>
                    <Button
                      children="Synchronize Prospects"
                      data-cy="syncPropertySubmit"
                      variant="tertiary"
                      type="submit"
                      disabled={isLoading}
                      onClick={onSyncProspects}
                    />
                    <p css={FONTS.h5}>
                      Suppress Invite Notifications and Enable Leasing Integration
                    </p>
                    <RadioRoot
                      id="uses_partner_enrollment"
                      value={usesPartnerEnrollment ? UsesPartnerEnrollmentStatus.YES : UsesPartnerEnrollmentStatus.NO}
                      onValueChange={(value: UsesPartnerEnrollmentStatus) => {
                        setUsesPartnerEnrollment(value == UsesPartnerEnrollmentStatus.YES ? true : false);
                      }}
                    >
                      <Radio.Option
                        data-cy="UsesPartnerEnrollmentStatusForYes"
                        value={UsesPartnerEnrollmentStatus.YES}
                        label="Yes"
                      />
                      <Radio.Option
                        data-cy="UsesPartnerEnrollmentStatusForNo"
                        value={UsesPartnerEnrollmentStatus.NO}
                        label="No"
                      />
                    </RadioRoot>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                  }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <label htmlFor="toggle-renters-insurance-enabled" css={[
                        FONTS.h5,
                        {
                          width: "100%",
                          lineHeight: 'normal',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          paddingBottom: 8,
                          borderBottom: `1px solid ${PALETTE.neutral12}`,
                          alignItems: 'flex-end'
                        }
                      ]}>
                        Allow Renters Insurance
                        <div style={{ flexShrink: 0, display: 'grid', placeContent: 'center' }}>
                          <Switch
                            id="toggle-renters-insurance-enabled"
                            aria-labelledby="toggle-guarantor-coverage-enabled"
                            aria-selected={rentersInsuranceEnabled}
                            checked={rentersInsuranceEnabled}
                            onCheckedChange={checked => setRentersInsuranceEnabled(checked)}
                            disabled={!usesPartnerEnrollment || !property.renters_insurance_offerable}
                          />
                        </div>
                      </label>
                    </div>
                    <p css={[FONTS.p2Medium, { marginBlockStart: 4, color: PALETTE.neutral55, opacity: property.renters_insurance_offerable ? 1 : 0.75 }]}>
                      Allow renters to purchase renters insurance during enrollment for this property.
                    </p>
                    {!property.renters_insurance_offerable ? (
                      <p css={[FONTS.p2Medium, { color: PALETTE.neutral55 }]}>
                        Renters insurance is not yet available in {property.address_state}.
                      </p>
                    ) : null }
                    {!usesPartnerEnrollment ? (
                      <p css={[FONTS.p2Medium, { color: PALETTE.neutral55 }]}>
                        Renters insurance is only available for leasing integration enrollment.
                      </p>
                    ) : null }
                  </div>
                </div>
              )}
          </div>
        )}
        <hr css={{ height: '0px', borderTop: '1px solid #ADB4B8', margin: '8px 0' }} />
        {user.isAdmin && (
          <div css={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <h4 css={[FONTS.h4]}>Cash Security Deposit Information</h4>
            <div css={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <p css={[FONTS.p1Extended]}>Cash Deposit Status</p>
              <RadioRoot
                id="cash_deposit_enabled"
                value={cashDeposit ? CashDepositStatus.ENABLE : CashDepositStatus.DISABLE}
                onValueChange={(value: CashDepositStatus) => {
                  setCashDeposit(value === CashDepositStatus.ENABLE ? true : false);
                }}
              >
                <Radio.Option
                  data-cy="enabledCashDeposits"
                  value={CashDepositStatus.ENABLE}
                  label="Enable cash deposits"
                />
                <Radio.Option
                  data-cy="disabledCashDeposits"
                  value={CashDepositStatus.DISABLE}
                  label="Disable cash deposits"
                />
              </RadioRoot>
            </div>

            <h4 css={[FONTS.h4]}>Partner Fund Transfer Status</h4>
            <div css={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <p css={[FONTS.p]}>Cash deposit will be directly deposited into a designated bank account.</p>
              <hr css={{ height: '0px', borderTop: '1px solid #ADB4B8', margin: '8px 0' }} />
              <div css={{ display: 'flex', gap: '4px' }}>
                <h5 css={[FONTS.h4]}>Funds Routing: </h5>
                <h5 css={[FONTS.h4]}>{property?.stripe_bank_account_display_name || 'Rhino Held'}</h5>
              </div>
            </div>
          </div>
        )}
      </section>

      {Boolean(errors['base']) && <ErrorNotification errors={errors['base']} />}
      <Button
        children={isLoading ? 'Loading' : 'Update property'}
        data-cy="singlePropertySubmit"
        variant="tertiary"
        type="submit"
        disabled={isLoading}
        onClick={onSubmit}
      />
    </FormView>
  );
};

function SPUEditPage({ user }: { user: SegmentUser }) {
  return (
    <UserContext.Provider value={{ user }}>
      <ToastContextProvider position="bottom-right">
        <Page>
          <Header>
            <LargeHeader>Edit Property</LargeHeader>
            <Close href="/admin/properties?table=true">
              <CloseIcon width="15px" height="15px" />
            </Close>
          </Header>
          <Form />
        </Page>
      </ToastContextProvider>
    </UserContext.Provider>
  );
}

export default SPUEditPage;
