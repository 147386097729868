import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Button, CloseButton, FONTS, PALETTE, TextInput } from '@sayrhino/rhino-shared-js';
import { useOverlay } from '../../utils/overlay';
import { Loading } from '@sayrhino/rhino-shared-js';
import { Splitter } from './ClaimStyles';
import useClaim from 'api/v2/useClaim';
import { useParams } from 'react-router-dom';
import updateAdjustedAmount from 'api/v2/updateClaimAmount';
import useToast from '../../toast/use-toast';
import { useMutation } from 'react-query';
import Decimal from 'decimal.js';
import { centsToUSDFormatter, sanitizeCurrencyInput } from '../../utils';
import { AxiosError } from 'axios';
import usePolicy from 'api/v2/usePolicy';
import { isAfter } from 'date-fns';

//Styles
const UpdateClaimAmountHeaderWrapper = styled.div({ padding: '16px 32px 0px 32px', alignItems: 'center' });
const UpdateClaimAmountText = styled.h4([
  FONTS.h4,
  {
    color: PALETTE.neutral55,
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '28px'
  }
]);
const StyledCloseButton = styled(CloseButton)({
  float: 'right'
});
const StyledTenantNameWrapper = styled.div({ padding: '0px' });
const StyledTenantName = styled.h3([FONTS.h3]);
const AdjustClaimCopyWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '32px 96px 48px 96px',
  width: '384px'
});
const StyledAdjustAmountCopy = styled.p([
  FONTS.P2Font,
  {
    fontWeight: '300',
    lineHeight: '28px'
  }
]);
const StyledSplitter = styled(Splitter)({
  border: `1px solid ${PALETTE.neutral12}`,
  width: '384px',
  top: '52px'
});
const StyledUpdateButton = styled(Button)({
  width: '109px',
  float: 'right'
});
const ButtonWrapper = styled.div({
  justifyContent: 'right',
  marginTop: '48px'
});
const StyledListItem = styled.li({
  listStyle: 'initial',
  listStylePosition: 'inside',
  textAlign: 'left'
});
const StyledBreak = styled.span({
  display: 'inline-block',
  marginLeft: '20px'
});
const TextInputWrapper = styled.div({ marginTop: '48px ' });

type UpdateError = {
  claim: any;
  errors: { adjusted_amount_cents: string[] };
};

const UpdateClaimAmountView = () => {
  const { claimsId } = useParams();
  const { policyId } = useParams();
  const { data: claim } = useClaim(Number(claimsId)) ?? {};
  const { data: policy } = usePolicy(Number(policyId));
  const navigate = useNavigate();
  const [adjustedAmount, setAdjustedAmount] = useState(claim?.adjusted_amount_cents || 0);
  const [adjustedAmountError, setAdjustedAmountError] = useState<string | undefined>();
  const { addToast } = useToast();
  const { mutate: updateAdjustedAmountMutation, isLoading } = useMutation(updateAdjustedAmount, {});
  const formattedAdjustedAmount = centsToUSDFormatter(new Decimal(adjustedAmount));
  const NEW_BOND_VERSION = '2021-01-01-coverage-after-bind';
  const canSeeFourthCopyPoint =
    policy?.bond_version === NEW_BOND_VERSION &&
    isAfter(new Date(claim?.date_of_loss!), new Date(policy.lease_end_date!));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAdjustedAmountError('');
    if (adjustedAmount) {
      updateAdjustedAmountMutation(
        { adjusted_amount_cents: adjustedAmount, id: claim?.id },
        {
          onSuccess: (e) => {
            navigate(-1);
            addToast('Claim Amount Adjusted');
          },
          onError: (error: AxiosError<UpdateError>) => {
            if (error.response?.status === 422) {
              const data = error?.response?.data;
              const errors = data?.claim?.errors;
              if (errors.adjusted_amount_cents) {
                setAdjustedAmountError(errors.adjusted_amount_cents.toString());
              }
            } else {
              navigate(-1);
              addToast('Something went wrong', 'error');
            }
          }
        }
      );
    }
  };
  return (
    <>
      <div>
        <UpdateClaimAmountHeaderWrapper>
          <StyledCloseButton onClick={() => navigate(-1)} aria-label="Leave update claim amount form" />
          <UpdateClaimAmountText>Update Claim Amount</UpdateClaimAmountText>
        </UpdateClaimAmountHeaderWrapper>
        <Splitter />
        <AdjustClaimCopyWrapper>
          <StyledTenantNameWrapper>
            <StyledTenantName> {claim?.renter_full_name} </StyledTenantName>
            <StyledSplitter />
          </StyledTenantNameWrapper>
          <StyledAdjustAmountCopy>
            Adjust the claim amount to the lesser of the following:
            <ul>
              <StyledListItem>
                The maximum security deposit amount allowable by <StyledBreak /> law where the property is located
              </StyledListItem>
              <StyledListItem>
                One (1) month’s rent as defined in the Lease <StyledBreak>Agreement</StyledBreak>
              </StyledListItem>
              <StyledListItem>The original claim amount </StyledListItem>
              {canSeeFourthCopyPoint && (
                <StyledListItem>
                  The amount of rent charged to the tenant during
                  <StyledBreak> the holdover period</StyledBreak>
                </StyledListItem>
              )}
            </ul>
          </StyledAdjustAmountCopy>
          <TextInputWrapper>
            <TextInput
              label={'Adjusted Claim Amount'}
              name="adjusted claim amount"
              id="adjustedAmount"
              value={formattedAdjustedAmount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (adjustedAmountError) setAdjustedAmountError(undefined);
                setAdjustedAmount(sanitizeCurrencyInput(e));
              }}
              subtext={adjustedAmountError}
              error={!!adjustedAmountError}
            />
          </TextInputWrapper>
          <ButtonWrapper>
            <StyledUpdateButton
              onClick={handleSubmit}
              children="Update"
              type="submit"
              disabled={isLoading || adjustedAmount === 0}
            />
          </ButtonWrapper>
        </AdjustClaimCopyWrapper>
      </div>
    </>
  );
};

const UpdateClaimAmountOverlay = () => {
  const { setShowOverlay } = useOverlay();
  const { claimsId } = useParams();
  const { isSuccess } = useClaim(Number(claimsId)) ?? {};
  React.useEffect(() => {
    setShowOverlay(true);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  return isSuccess ? <UpdateClaimAmountView /> : <Loading />;
};

export default UpdateClaimAmountOverlay;
