import { debounce } from 'lodash';
import React, { Component } from 'react';
import Table, { QueryParams, IPaginated } from '../Table';
import { BulkActionLink } from '../Styles';
import Toggle from 'components/active_states/Toggle';
import { csrfToken } from 'utils/document';
import { put, request } from 'utils/request';
import useFeatureFlags from 'hooks/v2/useFeatureFlags';

interface IProperty {
  id: number;
  building_name: string;
  address_line_one: string;
  address_city: string[];
  address_state: string;
  address_zip: string;
  owner_name: string;
  active: boolean;
}

interface IProps {
  properties: IPaginated<IProperty>;
  can_bulk_update: boolean;
  can_edit: boolean;
  can_destroy: boolean;
  view_advanced_properties: boolean;
  can_toggle: boolean;
}

interface IState {
  properties: IPaginated<IProperty>;
}

const PropertiesTable: React.FC<IProps> = ({
  properties,
  can_bulk_update,
  can_edit,
  can_destroy,
  can_toggle,
  view_advanced_properties
}) => {
  const { featureFlags } = useFeatureFlags();
  const [data, setData] = React.useState<IPaginated<IProperty>>(properties);

  const handleToggle = (prop, active) => {
    const updatePath = '/admin/properties/' + prop.id;
    const token = csrfToken();
    put(updatePath, { active, property_id: prop.id, authenticity_token: token }).catch(
      (response: { error: string }) => {
        // TODO: error reporting on the front end
      }
    );
  };

  const updateData = (params: QueryParams) => {
    const [pageIndex, pageSize, sortById, sortByDesc, filterValue, filters] = params;
    const query = {
      page_size: pageSize,
      page_index: pageIndex,
      filters
    };
    if (sortById !== undefined && sortByDesc !== undefined) {
      Object.assign(query, {
        sort_by: sortById,
        sort_desc: sortByDesc.toString()
      });
    }
    if (filterValue !== undefined) {
      Object.assign(query, {
        filter_value: filterValue
      });
    }
    request('/admin/properties', query).then((properties) => {
      setData(properties);
    });
  };

  const updateDataDebounced = debounce(updateData, 500);

  const bulkActions = ({ selectedIds }) => (
    <BulkActionLink onClick={() => bulkUpdate(selectedIds)}>Bulk Update</BulkActionLink>
  );

  const bulkUpdate = (propertyIds: [number]) => {
    const params = propertyIds.map((id) => `property_ids[]=${id}`).join('&');
    window.location.assign(`/admin/properties/bulk_edit?${params}`);
  };

  const columns = [
    view_advanced_properties
      ? {
          Header: 'PROPERTY ID',
          accessor: 'id',
          Cell: (props) => <a href={`/admin/properties/${props.row.original.id}`}>{props.row.original.id}</a>
        }
      : { id: 'id' },
    {
      Header: 'BUILDING NAME',
      accessor: 'building_name',
      displayFilter: true,
      Cell: (props) => <a href={`/admin/properties/${props.row.original.id}`}>{props.row.original.building_name}</a>
    },
    {
      Header: 'ADDRESS LINE ONE',
      accessor: 'address_line_one',
      displayFilter: true,
      Cell: (props) => <a href={`/admin/properties/${props.row.original.id}`}>{props.row.original.address_line_one}</a>
    },
    {
      Header: 'CITY',
      accessor: 'address_city',
      displayFilter: true
    },
    {
      Header: 'STATE',
      accessor: 'address_state',
      displayFilter: true
    },
    {
      Header: 'ZIP CODE',
      accessor: 'address_zip',
      displayFilter: true
    },
    view_advanced_properties
      ? {
          Header: 'OWNER NAME',
          accessor: 'owner_name',
          displayFilter: true
        }
      : { id: 'owner_name' },
    can_edit
      ? {
          Header: '',
          accessor: 'edit',
          Cell: (props) => (
            <a href={`/admin/properties/add/single/${props.row.original.id}`} style={styles.link}>
              Edit
            </a>
          )
        }
      : { id: 'edit' },
    can_destroy
      ? {
          Header: '',
          accessor: 'delete',
          Cell: (props) => {
            if (!props.row.original.associated_policy) {
              return (
                <a
                  href={`/admin/properties/${props.row.original.id}`}
                  data-method="delete"
                  data-property-id={props.row.original.id}
                  style={styles.link}
                >
                  Delete
                </a>
              );
            } else {
              return <div />;
            }
          }
        }
      : { id: 'delete' },
    can_toggle
      ? {
          Header: 'STATUS',
          accessor: 'active',
          Cell: (props) => (
            <Toggle
              onColor="#F5F4FB"
              handleToggle={(active) => handleToggle(props.data[props.row.id], active)}
              name={`property-owner-status-${props.row.id}`}
              checked={props.data[props.row.id].active}
            />
          )
        }
      : { id: 'toggle' }
  ];

  return (
    <Table
      paginatedData={data}
      columns={columns}
      update={updateDataDebounced}
      bulkActions={(can_bulk_update && ((props) => bulkActions(props))) || undefined}
    />
  );
};

const styles = {
  link: {
    color: '#6318CE'
  }
};

export default PropertiesTable;
