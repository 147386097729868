export enum Coverage {
  MULTIPLIER = 'MULTIPLIER',
  AMOUNT = 'DOLLAR_AMOUNT',
  DEFAULT = 'PROPERTY_DEFAULT'
}

export enum property_types {
  SINGLE = 'single_family',
  GARDEN = 'garden_community',
  SAGC = 'single_address_garden_community',
  MULTI = 'multifamily',
  STUDENT = 'Student Housing'
}

export enum account_types {
  CHECKING = 'checking',
  SAVINGS = 'savings'
}

export enum CashDepositStatus {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export enum UsesPartnerEnrollmentStatus {
  YES = 'yes',
  NO = 'no'
}

export enum TransferDeposits {
  YES = 'yes',
  NO = 'no'
}

export type Value = {
  id: string | number | undefined | null;
  name: string;
  __isNew__?: boolean;
};

export const integrationTypes = [
  'no_integration',
  'yardi',
  'realpage',
  'udr',
  'rent_manager',
  'mri',
  'entrata',
  'eqr'
] as const;

export type FormInputValues = {
  routing_number?: string | undefined;
  account_number?: string | undefined;
  bank_name?: string | undefined;
  name_on_account?: string | undefined;
  account_type?: account_types;
  id?: number;
  building_name?: string;
  address_line_one?: string;
  address_city?: string;
  address_zip?: string;
  address_state?: string;
  units: string[] | UnitInfo[]; // the form receives info from backend as UnitInfo, but when creating/updating, uses string[]. Not ideal.
  property_type?: property_types;
  coverage_details: {
    type: Coverage | string;
    value?: string | number;
    label?: string;
  };
  property_owner?: Value;
  policy_holder?: Value;
  integration_type?: typeof integrationTypes[number];
  integration_code?: number | string;
  multi_policy_holder?: boolean;
  multiple_owners?: boolean;
  can_edit_multiple_owners?: boolean;
  google_place_id?: string;
  slug?: string;
  property_manager_ids?: number[];
  listing_agent_ids?: number[];
  cash_deposit_enabled?: boolean;
  deposify_partner_bank_account_disabled?: boolean;
  uses_partner_enrollment?: boolean;
  stripe_bank_account_id: string | null;
  stripe_bank_account_display_name: string | null;
  renter_cash_deposit_portal_name: string | null;
  renter_cash_deposit_portal_url: string | null;
  renters_insurance_offerable?: boolean;
  renters_insurance_enabled?: boolean;
};

type UnitInfo = {
  address_city: string;
  address_line_one: string;
  address_line_two?: string;
  address_state: string;
  address_zip: string;
  id: number;
};

//Readonly options for Createable
export interface Option {
  readonly label: string;
  readonly value: string;
}
export interface State {
  readonly inputValue: string;
  readonly value: Option[];
}
