import React, { Fragment, useState, useEffect } from 'react';
import { getTagVariant, initializeDate, stringifyDate } from '../../utils';
import { formatMoney } from 'utils/money';
import { CaretIcon, EditIcon, CheckIcon, TextInput } from '@sayrhino/rhino-shared-js';
import { AnimatePresence, motion } from 'framer-motion';
import { ClaimStatus, IClaim, IMailAddress, IPolicyCardHeaderProps } from '../../interfaces';
import patchClaimApprovedAmount from 'api/v2/patchClaimApprovedAmount';
import useToast, { TOAST_STATUS } from '../../toast/use-toast';
import { AxiosError } from 'axios';

import {
  ClaimDetailsItem,
  ClaimDescription,
  Splitter,
  StyledClaimTag,
  StyledNewClaimTag,
  StatusWrapper,
  StyledIndicatorIcon,
  StyledLabelDiv,
  StyledDescriptionDiv,
  P2Font,
  StyledBarSpan,
  StyledCaretReportedLossDiv,
  StyledProgressBarDiv,
  StyledPolicyNumberDiv,
  StyledPolicyNumberAnchor,
  StyledClaimAmountDiv,
  StyledPaidClaimAmountDiv,
  StyledTotalClaimAmountDiv,
  StyledSeeMoreSeeLessButton,
  StyledReportedLossLabelDiv,
  RemainingCoverageWrapper,
  StyledRemainingCoverageSpan,
  StyledInnerProgressSpan,
  ItemizedLossesWrapper,
  ItemizedLossesHeader,
  LossItemWrapper,
  LossItemValueWrapper,
  LossItemLabelWrapper,
  StyledApprovedAmountDiv
} from './ClaimStyles';

export const isUnpaidClaim = (status: ClaimStatus) => {
  return [
    ClaimStatus.NEW,
    ClaimStatus.PROCESSING,
    ClaimStatus.ACCEPTED,
    ClaimStatus.DENIED,
    ClaimStatus.WITHDRAWN
  ].includes(status);
};

export const getFullMailingAddress = (address: IMailAddress) => {
  if (address.address_line_two !== null && address.address_line_two.length > 0) {
    return `${address.address_line_one}, ${address.address_line_two}, ${address.address_city}, ${address.address_state}, ${address.address_zip}`;
  } else {
    return `${address.address_line_one}, ${address.address_city}, ${address.address_state}, ${address.address_zip}`;
  }
};

export const ClaimTag = ({ status, maskedStatus }: { status: ClaimStatus; maskedStatus: string }) => {
  if (status === ClaimStatus.NEW) {
    return (
      <StyledNewClaimTag variant="state">
        <StatusWrapper>{maskedStatus}</StatusWrapper>
        <StyledIndicatorIcon />
      </StyledNewClaimTag>
    );
  }
  return <StyledClaimTag variant={getTagVariant(status)}>{maskedStatus}</StyledClaimTag>;
};

const currencyOrDash = (cents: number) => (cents ? formatMoney(cents).maskedValue : '-');

const EditableAprovedAmount = ({claimId, cents}: {claimId: number, cents: number}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [approvedAmountCents, setApprovedAmountCents] = useState(cents);
  const [fallbackAmountCents, setFallbackAmountCents] = useState(cents);
  const { addToast } = useToast();

  function formatCurrency(valueCents: number) {
    if (isNaN(valueCents)) return '';
    let usdValue = valueCents / 100;
    return usdValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const deformattedCents = parseFloat(event.target.value.replace(/[^0-9]+/g, ''));

    setApprovedAmountCents(deformattedCents);
  };

  const handleError = (error: AxiosError) => {
    const errorMessage = error?.response?.data?.errors ? error.response.data.errors : 'Something went wrong';

    setApprovedAmountCents(fallbackAmountCents);
    addToast(errorMessage, 'error', TOAST_STATUS.ERROR);
  }
  const submitApprovedAmount = (event) => {
    event.preventDefault();

    patchClaimApprovedAmount({id: claimId, approved_amount_cents: approvedAmountCents}).then(() => {
      setFallbackAmountCents(approvedAmountCents);
      addToast('Approved amount updated successfully!');
    }).catch(handleError).finally(() => setIsEnabled(false));
  }

  return (
    <ClaimDetailsItem>
      <StyledApprovedAmountDiv>
        {
          isEnabled ? (
            <form
              onSubmit={(e) => submitApprovedAmount(e) }
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '.3rem'
              }}
            >
              <TextInput
                id='approvedAmountCentsInput'
                value={formatCurrency(approvedAmountCents)}
                onChange={handleChange}
                style={{height: 'auto', margin: '0'}}
              />
              <button
                type="submit"
                id='submitApprovedAmountBtn'
                style={{
                  border: 'none',
                  background: 'transparent',
                  alignItems: 'center',
                  height: 'fit-content'
                }}
              >
                <CheckIcon
                  height={16}
                  width={16}
                  style={{ alignSelf: 'center', cursor: 'pointer' }}
                />
              </button>
            </form>
          ) : (
            <>
              {currencyOrDash(approvedAmountCents)}
              <EditIcon
                onClick={() => setIsEnabled(true)}
                height={16}
                width={16}
                style={{ alignSelf: 'center', cursor: 'pointer' }}
              />
            </>
          )
        }
      </StyledApprovedAmountDiv>
      <StyledLabelDiv>Approved Claim Amount</StyledLabelDiv>
    </ClaimDetailsItem>
  );
}

export const ClaimCardDetails = ({
  id,
  status,
  amount_cents,
  total_amount_paid_cents,
  property_owner,
  property,
  policy_holder,
  last_day_to_submit_claim,
  claim_amount_label,
  description,
  itemized_losses,
  is_claim_admin,
  approved_amount_cents
}: IClaim) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasItemizedLosses = Array.isArray(itemized_losses) && itemized_losses.length > 0;
  const itemizedLossDivs = hasItemizedLosses && (
    <>
      <AnimatePresence initial={false}>
        <motion.section
          key="content"
          initial="collapsed"
          animate={isOpen ? 'open' : 'collapsed'}
          variants={{
            open: { opacity: 1, height: 'auto', display: 'block' },
            collapsed: { opacity: 0, height: 0, display: 'none' }
          }}
          transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <ItemizedLossesWrapper>
            <ItemizedLossesHeader>Itemized Losses</ItemizedLossesHeader>
            {itemized_losses.map((item) => (
              <LossItemWrapper>
                <LossItemValueWrapper>{formatMoney(item.value).maskedValue}</LossItemValueWrapper>
                <LossItemLabelWrapper title={item.label}>{item.label}</LossItemLabelWrapper>
              </LossItemWrapper>
            ))}
          </ItemizedLossesWrapper>
        </motion.section>
      </AnimatePresence>
    </>
  );
  const claimAmountLabelWithShowItemizedLossesBtn = (
    <>
      <StyledSeeMoreSeeLessButton onClick={() => setIsOpen(!isOpen)}>
        <StyledReportedLossLabelDiv>{claim_amount_label}</StyledReportedLossLabelDiv>
        <motion.div
          animate={isOpen ? 'open' : 'collapsed'}
          variants={{
            open: { rotate: 180 },
            collapsed: { rotate: 0 }
          }}
          transition={{ duration: 0.2 }}
        >
          <StyledCaretReportedLossDiv>
            <CaretIcon width={10} height={4} />
          </StyledCaretReportedLossDiv>
        </motion.div>
      </StyledSeeMoreSeeLessButton>
    </>
  );

  const approvedClaimAmountDiv = is_claim_admin ? (
      <EditableAprovedAmount claimId={id} cents={approved_amount_cents}/>
    ) : (
      <>
        <ClaimDetailsItem>
          <P2Font>{currencyOrDash(approved_amount_cents)}</P2Font>
          <StyledLabelDiv>Approved Amount</StyledLabelDiv>
        </ClaimDetailsItem>
      </>
    )

  return (
    <>
      {isUnpaidClaim(status) ? (
        <>
          <ClaimDetailsItem>
            <StyledClaimAmountDiv amount_cents={amount_cents}>
              {formatMoney(amount_cents).maskedValue}
            </StyledClaimAmountDiv>
            {hasItemizedLosses ? (
              claimAmountLabelWithShowItemizedLossesBtn
            ) : (
              <>
                <StyledLabelDiv>{claim_amount_label}</StyledLabelDiv>
              </>
            )}
          </ClaimDetailsItem>
          {hasItemizedLosses && itemizedLossDivs}
          <hr />
          {approved_amount_cents !== undefined && (
            <>
              {approvedClaimAmountDiv}
              <hr />
            </>
          )}
          <ClaimDetailsItem>
            <P2Font>{stringifyDate(initializeDate(last_day_to_submit_claim))}</P2Font>
            <StyledLabelDiv>Last day to submit claims</StyledLabelDiv>
          </ClaimDetailsItem>
        </>
      ) : (
        <>
          <ClaimDetailsItem>
            <StyledPaidClaimAmountDiv total_amount_paid_cents={total_amount_paid_cents}>
              {formatMoney(total_amount_paid_cents).maskedValue}
            </StyledPaidClaimAmountDiv>
            <StyledLabelDiv>Amount paid</StyledLabelDiv>
          </ClaimDetailsItem>
          <hr />
          <ClaimDetailsItem>
            <StyledTotalClaimAmountDiv total_amount_paid_cents={total_amount_paid_cents}>
              {formatMoney(amount_cents).maskedValue}
            </StyledTotalClaimAmountDiv>
            {hasItemizedLosses ? (
              claimAmountLabelWithShowItemizedLossesBtn
            ) : (
              <>
                <StyledLabelDiv>{claim_amount_label}</StyledLabelDiv>
              </>
            )}
          </ClaimDetailsItem>
          {hasItemizedLosses && itemizedLossDivs}
          {approved_amount_cents !== undefined && (
            <>
              <hr />
              {approvedClaimAmountDiv}
            </>
          )}
        </>
      )}
      <hr />
      <ClaimDetailsItem>
        <P2Font>{property_owner.name}</P2Font> <StyledLabelDiv>Property Owner</StyledLabelDiv>
      </ClaimDetailsItem>
      <hr />
      <ClaimDetailsItem>
        <P2Font>{policy_holder.name}</P2Font> <StyledLabelDiv>Policy Holder</StyledLabelDiv>
      </ClaimDetailsItem>
      <hr />
      <ClaimDetailsItem>
        <P2Font>{property.full_address}</P2Font> <StyledLabelDiv>Address</StyledLabelDiv>
      </ClaimDetailsItem>
      {description !== undefined && description.length > 0 && (
        <>
          <hr />
          <ClaimDescription>
            <StyledLabelDiv>Description</StyledLabelDiv><StyledDescriptionDiv>{description}</StyledDescriptionDiv>
          </ClaimDescription>
        </>
      )}
    </>
  );
};

export const getClaimTitle = (claim: IClaim) => {
  if (claim.single_or_garden) {
    return `${claim.renter_full_name} | ${claim.claim_type}`;
  }
  return `${claim.renter_full_name} | ${`${claim.unit_name} -`} ${claim.claim_type}`;
};

export const PolicyCardHeader = ({ policy }: IPolicyCardHeaderProps) => {
    const remainingPolicyCoverage: number = Math.max(0, policy.remaining_coverage_amount_cents);
    const remainingPercentOfCoverage: number = policy
    ? (remainingPolicyCoverage / policy.coverage_amount_cents) * 100
    : 0;
  return (
    <Fragment>
      <ClaimDetailsItem>
        <RemainingCoverageWrapper remainingCoverage={remainingPolicyCoverage}>
          <StyledRemainingCoverageSpan remainingCoverage={remainingPolicyCoverage}>
            {formatMoney(policy ? remainingPolicyCoverage : 0).maskedValue}
          </StyledRemainingCoverageSpan>
          &nbsp;/&nbsp;{formatMoney(policy ? policy.coverage_amount_cents : 0).maskedValue}
        </RemainingCoverageWrapper>
        <StyledLabelDiv>Remaining coverage</StyledLabelDiv>
      </ClaimDetailsItem>
      <ClaimDetailsItem>
        <StyledProgressBarDiv>
          <StyledBarSpan>
            <StyledInnerProgressSpan remainingPercentOfCoverage={remainingPercentOfCoverage} />
          </StyledBarSpan>
        </StyledProgressBarDiv>
      </ClaimDetailsItem>
      <Splitter />
      <ClaimDetailsItem>
        <StyledPolicyNumberAnchor href={`/admin/renter_policies/${policy?.id}`}>
          <StyledPolicyNumberDiv>#{policy?.policy_number}</StyledPolicyNumberDiv>
        </StyledPolicyNumberAnchor>
        <StyledLabelDiv>Policy number</StyledLabelDiv>
      </ClaimDetailsItem>
      <Splitter />
      {policy?.bond_version && (
        <Fragment>
          <ClaimDetailsItem>
            <P2Font>{policy?.bond_version}</P2Font>
            <StyledLabelDiv>Bond Version</StyledLabelDiv>
          </ClaimDetailsItem>
          <Splitter />
        </Fragment>
      )}
      <ClaimDetailsItem>
        <P2Font>{policy?.policy_coverage_dates}</P2Font>
        <StyledLabelDiv>Coverage Dates</StyledLabelDiv>
      </ClaimDetailsItem>
      <Splitter />
      <ClaimDetailsItem>
        <P2Font>{policy?.lease_dates}</P2Font>
        <StyledLabelDiv>Lease Dates</StyledLabelDiv>
      </ClaimDetailsItem>
    </Fragment>
  );
};
