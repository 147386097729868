import { useState } from 'react';
import createPropertyOwner from 'api/v2/createPropertyOwner';
import { CoverageTypeEnum, FormMode, IRentersInsuranceLiabilityCoverage } from '../interfaces';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import useToast from '../toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import usePropertyOwnerDetails from 'api/v2/usePropertyOwnerDetails';
import useUpdatePropertyOwnerMutation from 'api/v2/updatePropertyOwner';
import { PROPERTY_OWNER_ROUTES } from '../utils/routeHelpers';
import useRentersInsuranceLiabilityCoverage from 'api/v2/useRentersInsuranceLiabilityCoverage';

const usePropertyOwnerForm = () => {
  const { propertyOwnerId } = useParams();
  const formMode = propertyOwnerId ? FormMode.EDIT : FormMode.CREATE;
  const coverageType: Record<string, CoverageTypeEnum> = {
    multiplier: CoverageTypeEnum.MULTIPLIER,
    dollarAmount: CoverageTypeEnum.DOLLAR_AMOUNT,
    amount_cents: CoverageTypeEnum.DOLLAR_AMOUNT,
    realpage_deposit_charges: CoverageTypeEnum.RP_DEPOSIT_CHARGES
  };

  const convertBooleanToOptionValue = (value?: boolean | null) => {
    switch (value) {
      case true:
        return 'yes';
      case false:
        return 'no';
      default:
        return '';
    }
  };

  const convertOptionValueToBoolean = (value?: string) => {
    switch (value) {
      case 'yes':
        return true;
      case 'no':
        return false;
      default:
        return null;
    }
  };

  const { data: propertyOwner, isLoading: isDetailsLoading } = usePropertyOwnerDetails(Number(propertyOwnerId)) ?? {};
  const { data: liabilityCoverageData } = useRentersInsuranceLiabilityCoverage();

  const getDefaultLiabilitySelection = () => {
    if (!propertyOwner?.renters_insurance_requirement_attributes?.liability_coverage) return null;
    return liabilityCoverageData?.liability_coverages.filter((coverage) => {
      return coverage.label === propertyOwner?.renters_insurance_requirement_attributes?.liability_coverage;
    })[0];
  };

  const navigate = useNavigate();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const [name, setName] = useState<string>(propertyOwner?.name ?? '');
  const [nameError, setNameError] = useState<string>();
  const [errorMessageForAmount, setErrorMessageForAmount] = useState<string>();
  const [coverageMultiplierLimitsError, setCoverageMultiplierLimitsError] = useState<string>();
  const [defaultCoverageAmount, setDefaultCoverageAmount] = useState<number>(
    propertyOwner?.default_coverage_amount_cents || 0
  );
  const [coverageMultiplierLimits, setCoverageMultiplierLimits] = useState<string[]>(
    propertyOwner?.coverage_multiplier_limits || []
  );
  const [claimClassificationTag, setClaimClassificationTag] = useState<string>(
    propertyOwner?.claim_classification ?? ''
  );
  const [defaultCoverageType, setDefaultCoverageType] = useState<CoverageTypeEnum>(
    propertyOwner?.default_coverage_type
      ? coverageType[propertyOwner?.default_coverage_type]
      : CoverageTypeEnum.MULTIPLIER
  );
  const [maxCoverageAmount, setMaxCoverageAmount] = useState<number | undefined>(
    propertyOwner?.max_coverage_amount_cents
  );
  const [isMaxCoverageAmountChecked, setIsMaxCoverageAmountChecked] = useState<boolean>(
    !!propertyOwner?.max_coverage_amount_cents
  );
  const [maxCoverageError, setmaxCoverageError] = useState<string>();

  const [RIRequiredSelection, setRIRequiredSelection] = useState<string>(
    convertBooleanToOptionValue(propertyOwner?.renters_insurance_requirement_attributes?.requires_renters_insurance)
  );

  const [requiresInvitation, setRequiresInvitation] = useState<string>(
    convertBooleanToOptionValue(propertyOwner?.requires_invitation)
  );

  const [liabilityCoverage, setLiabilityCoverage] = useState<IRentersInsuranceLiabilityCoverage | null>(
    getDefaultLiabilitySelection() || null
  );

  const [exclusiveMarketingAgreementExists, setExclusiveMarketingAgreementExists] = useState(
    convertBooleanToOptionValue(propertyOwner?.renters_insurance_requirement_attributes?.has_exclusive_agreement)
  );

  const { mutate: createPropertyOwnerMutation } = useMutation(createPropertyOwner, {});
  const { mutate: updatePropertyOwnerMutation, isLoading: isUpdateLoading } = useUpdatePropertyOwnerMutation();
  const maxCoverageAmountSet = isMaxCoverageAmountChecked ? maxCoverageAmount : undefined;
  const canUpdateClaimClassification = propertyOwner?.actions?.can_update_claim_classification;
  const getPayloadForCoverage = () => {
    if (defaultCoverageType === CoverageTypeEnum.DOLLAR_AMOUNT) {
      return {
        default_coverage_amount_cents: defaultCoverageAmount,
        max_coverage_amount_cents: maxCoverageAmountSet
      };
    } else {
      return {
        coverage_multiplier_limits: coverageMultiplierLimits
      };
    }
  };

  const getPayloadForRentersInsurance = () => {
    if (RIRequiredSelection || exclusiveMarketingAgreementExists) {
      return {
        renters_insurance_requirement_attributes: {
          id: propertyOwner?.renters_insurance_requirement_attributes?.id || null,
          requires_renters_insurance: convertOptionValueToBoolean(RIRequiredSelection),
          liability_coverage: convertOptionValueToBoolean(RIRequiredSelection) ? liabilityCoverage?.value : null,
          has_exclusive_agreement: convertOptionValueToBoolean(exclusiveMarketingAgreementExists)
        }
      };
    }
  };

  const create = (covgType: string, propertyOwnerId?: number) => {
    createPropertyOwnerMutation(
      {
        name: name,
        requires_invitation: convertOptionValueToBoolean(requiresInvitation),
        default_coverage_type: covgType,
        id: propertyOwnerId,
        claim_classification: claimClassificationTag,
        ...getPayloadForCoverage(),
        ...getPayloadForRentersInsurance()
      },

      {
        onSuccess: (res) => {
          const newId = res.data.id;
          const path = generatePath(PROPERTY_OWNER_ROUTES.details, {
            propertyOwnerId: String(newId)
          });
          queryClient.invalidateQueries('adminPropertyOwners');
          navigate(`${path}`);
          addToast('Property Owner Added');
        },
        onError: (e: AxiosError) => {
          handleError(e);
        }
      }
    );
  };

  const update = (covgType: string, propertyOwnerId: number, claim_classification?) => {
    updatePropertyOwnerMutation(
      {
        name: name,
        requires_invitation: convertOptionValueToBoolean(requiresInvitation),
        default_coverage_type: coverageType[covgType],
        id: propertyOwnerId,
        claim_classification: claim_classification,
        ...getPayloadForCoverage(),
        ...getPayloadForRentersInsurance()
      },
      {
        onSuccess: (_e) => {
          queryClient.invalidateQueries('adminPropertyOwners');
          navigate(-1);
          addToast('Property Owner Information was successfully updated');
        },
        onError: (e: AxiosError) => {
          handleError(e);
        }
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const coverageType: string = defaultCoverageType;
    if (formMode === FormMode.CREATE) {
      create(coverageType);
    } else {
      update(coverageType, Number(propertyOwnerId), claimClassificationTag);
    }
  };

  const handleError = (error : AxiosError) => {
    if (error.response?.status === 422) {
      const data = error.response?.data;
      const errors = data.errors;
      if (errors.name) setNameError(errors.name[0]);
      if (errors.default_coverage_amount_cents) setErrorMessageForAmount(errors.default_coverage_amount_cents[0]);
      if (errors.max_coverage_amount_cents) setmaxCoverageError(errors.max_coverage_amount_cents[0]);
      if (errors.coverage_multiplier_limits) setCoverageMultiplierLimitsError(errors.coverage_multiplier_limits[0]);
    } else {
      navigate(-1);
      addToast('Something went wrong', 'error');
    }
  }

  return {
    handleSubmit,
    name,
    setName,
    nameError,
    setNameError,
    coverageMultiplierLimitsError,
    setCoverageMultiplierLimitsError,
    canUpdateClaimClassification,
    defaultCoverageAmount,
    setDefaultCoverageAmount,
    coverageMultiplierLimits,
    setCoverageMultiplierLimits,
    defaultCoverageType,
    setDefaultCoverageType,
    maxCoverageAmount,
    setMaxCoverageAmount,
    isMaxCoverageAmountChecked,
    setIsMaxCoverageAmountChecked,
    errorMessageForAmount,
    setErrorMessageForAmount,
    liabilityCoverage,
    setLiabilityCoverage,
    getDefaultLiabilitySelection,
    RIRequiredSelection,
    setRIRequiredSelection,
    isLoading: isDetailsLoading || isUpdateLoading,
    formMode,
    maxCoverageError,
    exclusiveMarketingAgreementExists,
    setExclusiveMarketingAgreementExists,
    claimClassificationTag,
    setClaimClassificationTag,
    requiresInvitation,
    setRequiresInvitation
  };
};

export default usePropertyOwnerForm;
