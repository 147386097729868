import React, { Fragment, useState } from 'react';
import { CaretIcon } from '@sayrhino/rhino-shared-js';
import { initializeDate, stringifyDate } from '../../utils';
import { AnimatePresence, motion } from 'framer-motion';
import { ClaimStatus, IClaim } from '../../interfaces';
import { useUserRole, useIsClaimAdmin } from '../../utils/userRole';

import {
  ClaimDetailsItem,
  Splitter,
  StyledLabelDiv,
  P2Font,
  StyledCaretDiv,
  StyledCardDetails,
  StyledCardDetailsHeaderDiv,
  StyledClaimTypeClaimNumber,
  StyledSeeMoreSeeLessButton,
  StyledSeeMoreDiv,
  StyledEditPaymentLink,
  StyledEditPaymentDiv
} from './ClaimStyles';

import { ClaimCardDetails, isUnpaidClaim, getFullMailingAddress } from './ClaimCardDetails';

const shouldEditPayment = (status: ClaimStatus) => {
  return [ClaimStatus.ACCEPTED, ClaimStatus.PAID, ClaimStatus.WITHDRAWN].includes(status);
};

export const ClaimDetailsCard = (claim: IClaim) => {
  const [isOpen, setIsOpen] = useState(false);
  const userRole = useUserRole();
  const { isAdmin } = userRole;
  const isClaimAdmin = useIsClaimAdmin();
  const dateOfLoss = claim.date_of_loss ? stringifyDate(initializeDate(claim.date_of_loss.toString())) : null;

  return (
    <StyledCardDetails variant="border">
      <StyledCardDetailsHeaderDiv>
        <StyledClaimTypeClaimNumber>{`${claim.claim_type} - #${claim.claim_number}`}</StyledClaimTypeClaimNumber>
        <StyledSeeMoreSeeLessButton onClick={() => setIsOpen(!isOpen)}>
          <StyledSeeMoreDiv>{isOpen ? 'see less' : 'see more'}</StyledSeeMoreDiv>
          <motion.div
            animate={isOpen ? 'open' : 'collapsed'}
            variants={{
              open: { rotate: 180 },
              collapsed: { rotate: 0 }
            }}
            transition={{ duration: 0.2 }}
          >
            <StyledCaretDiv>
              <CaretIcon width={10} height={4} />
            </StyledCaretDiv>
          </motion.div>
        </StyledSeeMoreSeeLessButton>
      </StyledCardDetailsHeaderDiv>
      <ClaimCardDetails {...{ ...claim, is_claim_admin: !!isClaimAdmin }} />
      <AnimatePresence initial={false}>
        <motion.section
          key="content"
          initial="collapsed"
          animate={isOpen ? 'open' : 'collapsed'}
          variants={{
            open: { opacity: 1, height: 'auto', display: 'block' },
            collapsed: { opacity: 0, height: 0, display: 'none' }
          }}
          transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {claim.tenant_vacated_unit_date && (
            <>
              <hr />
              <ClaimDetailsItem>
                <P2Font>{stringifyDate(initializeDate(claim.tenant_vacated_unit_date))}</P2Font> <StyledLabelDiv>Tenant Vacated Date</StyledLabelDiv>
              </ClaimDetailsItem>
            </>
          )}
          {claim.tenant_provided_notice_date && (
            <>
              <hr />
              <ClaimDetailsItem>
                <P2Font>{stringifyDate(initializeDate(claim.tenant_provided_notice_date))}</P2Font> <StyledLabelDiv>Tenant Gave Notice Date</StyledLabelDiv>
              </ClaimDetailsItem>
            </>
          )}
          {!isUnpaidClaim(claim.status) && (
            <Fragment>
              <hr />
              <ClaimDetailsItem>
                <P2Font>{stringifyDate(initializeDate(claim.last_day_to_submit_claim))}</P2Font>
                <StyledLabelDiv>Last day to submit claims</StyledLabelDiv>
              </ClaimDetailsItem>
            </Fragment>
          )}
          {isUnpaidClaim(claim.status) && (
            <Fragment>
              <hr />
              <ClaimDetailsItem>
                <P2Font>{stringifyDate(initializeDate(claim.created_date))}</P2Font>
                <StyledLabelDiv>Claim Report Date</StyledLabelDiv>
              </ClaimDetailsItem>
            </Fragment>
          )}
          <hr />
          {claim.date_of_loss && (
            <Fragment>
              <ClaimDetailsItem>
                <P2Font> {dateOfLoss} </P2Font> <StyledLabelDiv> Date of Loss</StyledLabelDiv>
              </ClaimDetailsItem>
              <hr />
            </Fragment>
          )}
          {claim.earliest_date_of_loss && (
            <Fragment>
              <ClaimDetailsItem>
                <P2Font> {stringifyDate(initializeDate(claim.earliest_date_of_loss))} </P2Font>
                <StyledLabelDiv> Earliest Date of Loss</StyledLabelDiv>
              </ClaimDetailsItem>
              <hr />
            </Fragment>
          )}
          <ClaimDetailsItem>
            <P2Font>{claim.property.name}</P2Font> <StyledLabelDiv>Property Name</StyledLabelDiv>
          </ClaimDetailsItem>
          <hr />
          <ClaimDetailsItem>
            <P2Font>{claim.payment_option_selected}</P2Font>
            <StyledLabelDiv>Payment Option Selected</StyledLabelDiv>
          </ClaimDetailsItem>
          {claim.payment_option_selected === 'ACH' && claim.masked_destination_account_number && (
            <Fragment>
              <hr />
              <ClaimDetailsItem>
                <P2Font>{claim.masked_destination_account_number}</P2Font>
                <StyledLabelDiv>Destination Account Number</StyledLabelDiv>
              </ClaimDetailsItem>
            </Fragment>
          )}
          {claim.payment_option_selected === 'check' && claim.mailing_address && (
            <Fragment>
              <hr />
              <ClaimDetailsItem>
                <P2Font>{getFullMailingAddress(claim.mailing_address)}</P2Font>
                <StyledLabelDiv>Mailing Address</StyledLabelDiv>
              </ClaimDetailsItem>
            </Fragment>
          )}
          {!!claim.created_by.full_name && (
            <Fragment>
              <hr />
              <ClaimDetailsItem>
                <P2Font>{claim.created_by.full_name}</P2Font>
                <StyledLabelDiv>Reporting Party</StyledLabelDiv>
              </ClaimDetailsItem>
            </Fragment>
          )}
          <hr />
          <ClaimDetailsItem>
            <P2Font>{claim.created_by.email}</P2Font> <StyledLabelDiv>Email</StyledLabelDiv>
          </ClaimDetailsItem>
        </motion.section>
      </AnimatePresence>
      {isAdmin && shouldEditPayment(claim.status) && (
        <React.Fragment>
          <Splitter />
          <StyledEditPaymentDiv>
            <StyledEditPaymentLink to={`/admin/claims/${claim.id}/claim_payouts`}>Edit Payments</StyledEditPaymentLink>
          </StyledEditPaymentDiv>
        </React.Fragment>
      )}
    </StyledCardDetails>
  );
};

export default ClaimDetailsCard;
