import axios from 'axios';
import { csrfToken } from 'utils/document';
import { IClaim } from 'components/v2/App/interfaces';

const updateAdjustedAmount = async (updatedClaim: Partial<IClaim>) => {
  const authenticity_token = csrfToken();
  const { id, ...claim } = updatedClaim;
  const { data } = await axios.put<IClaim>(`/v2/admin/claims/${id}/update_adjusted_amount`, claim, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });
  return data;
};

export default updateAdjustedAmount;
