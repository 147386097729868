/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useState } from 'react';
import { Button, FONTS, TextInput, DateInput, CalendarIcon, Select, Switch, PALETTE } from '@sayrhino/rhino-shared-js';
import '@sayrhino/rhino-shared-js/build/datepicker.css';
import { parseIntDecimal, stringifyDate, addYears, firstOfMonth } from '../utils';
import { PropertyInviteTypeahead } from '../CreateInvitationTypeahead/PropertyInviteTypeahead';
import { PropertyUnitInviteTypeahead } from '../CreateInvitationTypeahead/PropertyUnitInviteTypeahead';
import {
  RentalStatusContainer,
  DatesContainer,
  StartDateInputContainer,
  EndDateInputContainer,
  StyledLine,
  ErrorText,
  errorCSS,
  StyledTitle,
  StyledDescription,
  StyledH4
} from './Styles';
import useCreateInvitationHook, { CoverageOptionsRadioButtonsWrapper } from './useCreateInvitationHook';
import usePropertyDetails from 'api/v2/usePropertyDetails';
import { CoverageOptions } from './CoverageOptions';

const StyledFieldLabel = styled.label(
  [FONTS.p3Medium, { color: PALETTE.neutral65, marginBottom: 0 }]
);

const StyledSpan = styled.label(
  [FONTS.p3Medium, { color: PALETTE.neutral90, marginBottom: 0 }]
);

const StyledLink = styled.a({
  color: PALETTE.neutral90,
  textDecoration: 'underline',
  cursor: 'pointer'
});

const StyledDiv = styled.div({
  width: '100%',
  height: '56px',
  borderRadius: '8px',
  border: '1px solid #F1C21B',
  padding: '16px 12px',
  gap: '16px',
  backgroundColor: '#FCF4D6',
  margin: '4px 0px'
});

interface ISelectedProperty {
  full_address: string;
  cash_deposit_enabled: boolean;
  guarantor_coverage_capable: boolean;
  use_residents_tab: boolean;
}

export const RENTAL_STATUS_OPTIONS = [
  { label: 'Vacancy', value: '0' },
  { label: 'Mid-Lease', value: '1' },
  { label: 'Renewal', value: '2' }
];

const renewalRequests = (window as any).App?.featureFlags?.renewalRequests;
const inviteCashDepositOnly = (window as any).App?.featureFlags?.inviteCashDepositOnly;

const CreateInvitationForm = ({ createErrorMsg }) => {
  const {
    AddressRef,
    collectCashDepositEnabled,
    coverageSelectionState,
    endDate,
    errors,
    guarantorCoverageEnabled,
    handleEmailChange,
    handleChange,
    handleLeaseEndDateChange,
    handleLeaseStartDateChange,
    handlePhoneChange,
    invitation,
    isFormValid,
    onPropertyChange,
    onUnitChange,
    phone,
    setCashDepositEnabled,
    setCollectCashDepositEnabled,
    setInvitation,
    startDate,
    submitForm,
    UnitRef
  } = useCreateInvitationHook();

  const [selectedProperty, setSelectedProperty] = useState<ISelectedProperty>();

  return (
    <div>
      {!renewalRequests && <StyledTitle>New Invitation</StyledTitle>}
      <StyledDescription>Please select the correct information below for the applicant.</StyledDescription>
      <StyledH4>Tenant</StyledH4>
      <StyledLine />
      <TextInput
        id="firstName"
        label="First Name"
        data-cy="tenantFirstName"
        onChange={(event) => handleChange(event, 'first_name')}
        subtext={
          errors &&
          errors?.first_name && (
            <p className="infoError" css={errorCSS}>
              First name {errors.first_name}
            </p>
          )
        }
      />
      <TextInput
        id="lastName"
        label="Last Name"
        data-cy="tenantLastName"
        onChange={(event) => handleChange(event, 'last_name')}
        subtext={
          errors &&
          errors?.last_name && (
            <p className="infoError" css={errorCSS}>
              Last name {errors.last_name}
            </p>
          )
        }
      />
      <TextInput
        id="email"
        label="Email"
        data-cy="tenantEmail"
        onChange={(event) => handleEmailChange(event)}
        subtext={
          errors &&
          errors?.email && (
            <p className="infoError" css={errorCSS}>
              Email {errors.email}
            </p>
          )
        }
      />
      <TextInput
        id="phone"
        label="Phone number"
        data-cy="tenantPhone"
        maxLength={14}
        value={phone}
        type="tel"
        onChange={(event) => handlePhoneChange(event)}
        subtext={
          errors &&
          errors?.phone && (
            <p className="infoError" css={errorCSS}>
              Phone number {errors.phone}
            </p>
          )
        }
      />
      <RentalStatusContainer title="Rental status container">
        <Select
          data-cy="tenantRentalStatus"
          options={RENTAL_STATUS_OPTIONS}
          label="Rental status"
          onChange={(value: any) => {
            setInvitation({ ...invitation, rental_status: parseIntDecimal(value.value) });
          }}
          placeholder=""
          id="rentalStatus"
        />
      </RentalStatusContainer>
      <h4 css={[FONTS.h5]}>Property</h4>
      <StyledLine />
      <div>

      </div>
      <div title="Lease address container">
        {selectedProperty?.use_residents_tab && (
          <div>
            <StyledFieldLabel>Lease address</StyledFieldLabel>
            <StyledDiv>
              <StyledSpan>{selectedProperty?.full_address}</StyledSpan>
            </StyledDiv>
            <div css={{paddingBottom: '16px', paddingTop: '5px'}}>
              <StyledSpan>
                The resident for this property is already in our system and can be found in the <StyledLink href={`/admin/partners/residents`}>Residents tab</StyledLink>. Invitations are no longer needed for this property.
              </StyledSpan>
            </div>
          </div>
        )}
        {!selectedProperty?.use_residents_tab && (
          <PropertyInviteTypeahead
            data-cy="apartmentAddress"
            onSelect={onPropertyChange}
            ref={AddressRef}
            setSelectedProperty={setSelectedProperty}
            setCashDepositEnabled={setCashDepositEnabled}
          />
        )}
        {errors && errors?.property && createErrorMsg(errors.property[0])}
      </div>
      <div>
        <PropertyUnitInviteTypeahead
          data-cy="apartmentLeaseUnit"
          ref={UnitRef}
          propertyId={invitation && invitation.property_id}
          onSelect={onUnitChange}
        />
        {errors && errors?.unit && createErrorMsg(errors.unit[0])}
      </div>
      <DatesContainer>
        <StartDateInputContainer>
          <DateInput
            id="startDate"
            inputLabel="Lease start"
            minDate={addYears(new Date(), -1)}
            maxDate={addYears(new Date(), 5)}
            selected={startDate}
            onChange={{
              onChange: (newDate: Date) => handleLeaseStartDateChange(newDate),
              onChangeRaw: (e) => console.log(e)
            }}
            value={stringifyDate(startDate)}
            data-cy="leaseStateDate"
            customInput={<TextInput id="startDate" label="Lease start" rightIcon={<CalendarIcon />} />}
          />
          {errors && errors?.lease_start_date && <ErrorText>{errors.lease_start_date}</ErrorText>}
        </StartDateInputContainer>
        <EndDateInputContainer>
          <DateInput
            id="endDate"
            inputLabel="Lease end"
            minDate={new Date(startDate)}
            maxDate={addYears(new Date(startDate), 3)}
            selected={endDate}
            onChange={{
              onChange: (newDate: Date) => handleLeaseEndDateChange(newDate),
              onChangeRaw: (e) => console.log(e)
            }}
            onChangeRaw={(e) => {}}
            value={stringifyDate(endDate)}
            data-cy="leaseEndDate"
            customInput={<TextInput id="endDate" label="Lease end" rightIcon={<CalendarIcon />} />}
          />
          {errors && errors?.lease_end_date && <ErrorText>End date {errors.lease_end_date}</ErrorText>}
        </EndDateInputContainer>
      </DatesContainer>
      <div
        css={{
          marginBottom: 32,
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0
          },
          "input[type='number']": {
            MozAppearance: 'textfield'
          }
        }}
      >
        <TextInput
          id="monthlyRent"
          label="Monthly rent"
          data-cy="apartmentMonthlyRent"
          leftIcon="$"
          type="number"
          onWheel={(event) => event.currentTarget.blur()}
          onChange={(event) => handleChange(event, 'monthly_rent')}
          subtext={
            errors?.monthly_rent_cents && (
              <p className="infoError" css={errorCSS}>
                Monthly rent {errors?.monthly_rent_cents.join()}
              </p>
            )
          }
        />
      </div>

      {!selectedProperty?.use_residents_tab && (
        <div>
          {selectedProperty?.guarantor_coverage_capable ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBlock: '24px' }}>
              <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                <h4 css={[FONTS.h5]}>Guarantor Coverage</h4>
                <Switch
                  aria-label={`Toggle Guarantor`}
                  id={'guarantor-enabled-toggle'}
                  onCheckedChange={(checked) => handleChange(checked, 'guarantor_coverage')}
                />
              </div>
              {selectedProperty.cash_deposit_enabled ? (
                <p css={[FONTS.p1Light]}>Cash Deposit not permitted with Guarantor Coverage enabled.</p>
              ) : null}
            </div>
          ) : null}

          {selectedProperty?.cash_deposit_enabled ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', marginBlock: '24px' }}>
              {!collectCashDepositEnabled ? (
                <CoverageOptions
                  sectionHeader={'Deposit Insurance Coverage Amount'}
                  invitation={invitation}
                  CoverageOptionsRadioButtonsWrapper={CoverageOptionsRadioButtonsWrapper}
                  coverageSelectionState={coverageSelectionState}
                  errors={errors}
                />
              ) : null}

              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <h4 css={[FONTS.h5]}>Security Deposit Requirement</h4>
                  <p css={[FONTS.p1Light]}>Set your security deposit requirement options.</p>
                </div>
                <hr style={{ width: '100%', borderTop: `1px solid ${PALETTE.neutral4}`, margin: '0' }} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <h4 css={[FONTS.h5]}>Cash Deposit Amount</h4>
                  <p css={[FONTS.p1Light]}>Set the required security deposit amount.</p>
                </div>
                <TextInput
                  id="securityCashDeposit"
                  label="Security Deposit Amount"
                  data-cy="securityCashDeposit"
                  leftIcon="$"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(event) => handleChange(event, 'cash_deposit_amount')}
                  value={invitation?.cash_deposit_amount || ''}
                  disabled={guarantorCoverageEnabled}
                  subtext={
                    errors?.cash_deposit_amount_cents && (
                      <p className="infoError" css={errorCSS}>
                        Security Deposit Amount {errors?.cash_deposit_amount_cents.join()}
                      </p>
                    )
                  }
                />
                {!guarantorCoverageEnabled && inviteCashDepositOnly && (
                  <div style={{ display: 'flex', gap: '16px', alignContent: 'center' }}>
                    <Switch
                      aria-label={`Toggle partner coverage rules`}
                      id={'coverage-toggle-partner'}
                      onCheckedChange={() => setCollectCashDepositEnabled(!collectCashDepositEnabled)}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <h5 css={[FONTS.h6]}>Collect Cash Deposits only</h5>
                      <p css={[FONTS.p2Light]}>Renter will not have the option to buy deposit insurance.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBlock: '24px' }}>
              <CoverageOptions
                sectionHeader={'Coverage'}
                invitation={invitation}
                CoverageOptionsRadioButtonsWrapper={CoverageOptionsRadioButtonsWrapper}
                coverageSelectionState={coverageSelectionState}
                errors={errors}
              />
            </div>
          )}

          <Button
            data-cy="sendInvitation"
            variant="primary"
            onClick={submitForm}
            disabled={!isFormValid}
            css={{
              '&:disabled': {
                border: '1px solid'
              },
              float: 'right',
              marginBottom: 56
            }}
          >
            Send invitation
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateInvitationForm;
