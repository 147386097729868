import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';
import { IPMAPropertyOwnerEditPOST, IPMAPropertyOwnerDetails } from 'components/v2/App/interfaces';
import { useMutation, MutationFunction } from 'react-query';

interface IUpdatePropertyOwnerResponse {
  errors?: string[];
}

const updatePropertyOwner: MutationFunction<
  AxiosResponse<IUpdatePropertyOwnerResponse>,
  IPMAPropertyOwnerEditPOST
> = async (propertyOwner: Partial<IPMAPropertyOwnerDetails>): Promise<AxiosResponse<IUpdatePropertyOwnerResponse>> => {
  const authenticity_token = csrfToken();
  const {
    name,
    default_coverage_type,
    coverage_multiplier_limits,
    max_coverage_amount_cents,
    default_coverage_amount_cents,
    requires_invitation,
    renters_insurance_requirement_attributes,
    claim_classification,
    deposit_accounts_enabled,
    guarantor_coverage_capable
  } = propertyOwner;

  const response: AxiosResponse<IUpdatePropertyOwnerResponse> = await axios.put(
    `/v2/admin/property_owners/${propertyOwner.id}`,
    {
      property_owner: {
        name,
        default_coverage_type,
        coverage_multiplier_limits,
        max_coverage_amount_cents: max_coverage_amount_cents === undefined ? null : max_coverage_amount_cents,
        default_coverage_amount_cents,
        requires_invitation,
        renters_insurance_requirement_attributes,
        claim_classification,
        deposit_accounts_enabled,
        guarantor_coverage_capable
      }
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useUpdatePropertyOwnerMutation() {
  return useMutation(updatePropertyOwner);
}
