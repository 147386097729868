/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState, useEffect } from 'react';
import {
  MinimizeIcon,
  LinkIcon,
  Loading,
  PersonCircleIcon,
  DropdownMenu,
  SquareButton,
  CheckIcon
} from '@sayrhino/rhino-shared-js';
import { useNavigate, useParams } from 'react-router-dom';
import { initializeDate, stringifyDate, useReferrer } from '../../utils';
import { useClipboard } from 'use-clipboard-copy';
import { AnimatePresence } from 'framer-motion';
import { generateMaskedStatus } from '../../utils/generateMaskedStatus';
import useClaim from 'api/v2/useClaim';
import useAdministrators from 'api/v2/useAdministrators';
import { ClaimStatus, IClaim, IClaimStatusOption, IClaimAttachment } from '../../interfaces';
import { useUserContext, useUserRole } from '../../utils/userRole';
import assignAdmin from 'api/v2/assignAdmin';
import ToggleControlsButton from '../../ToggleControlsButton';
import useToast, { TOAST_STATUS } from '../../toast/use-toast';
import withdrawClaim from 'api/v2/withdrawClaim';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import useUpdateClaimStatus from 'api/v2/updateClaimStatus';
import FullScreenModal from '../../Integrations/Modal';

import {
  ClaimsDetailWrapper,
  ClaimDetailWrapper,
  AdminEditWrapper,
  ClaimTitleWrapper,
  ClaimDetailViewWrapper,
  StyledAssigneeInitialsP,
  ClaimDetailIndividualWrapper,
  StyledReferrerLink,
  StyledCopyLinkButton,
  StyledCopyConfirmationSpan,
  StyledDateCreatedSpan,
  DateCreatedWrapper,
  DropdownMenuSplitter,
  DropdownItem,
  DropdownContent,
  SelectedDropdownCheckmark,
  DrawerModalHeader,
  DrawerModalText,
  DrawerModalFooter,
  TenantNotification,
  Icon,
  SectionDivider,
  StyledModal,
  StyledModalTitle,
  StyledModalSubtitle,
  ButtonGroup,
  StyledHeader,
  PrimaryButton,
  SecondaryButton,
  StyledClaimDetailsItem,
  StyledOtherClaimTag
} from './ClaimStyles';

import { ClaimTag, getClaimTitle } from './ClaimCardDetails';
import NoteAndAttachmentsForm from './NoteAndAttachmentsForm';
import ClaimNotes from './ClaimNotes';
import useClaimAttachments from 'api/v2/useClaimAttachments';
import ClaimAttachments from './ClaimAttachments';
import { SubrogationDetails } from './SubrogationDetails';
import { ApprovedClaimAmountCard } from './ApprovedClaimAmount';
import FileViewer from '../../FileViewer';
import ClaimNotification from './ClaimNotification';
import ClaimAction from './ClaimAction';
import ClaimDetailsCard from './ClaimDetailsCard';
import PolicyDetailsCard from './PolicyDetailsCard';
import ActionButtons from './ActionButtons';
import { Tab, TabList, Tabs, TabContent } from '../../Tabs/Tab';
import ClaimChangelog from './ClaimChangelog';
import { getClaimAttachmentPath } from '../../utils/routeHelpers';

export const ClaimDetailsView = () => {
  const { claimsId } = useParams();
  const { data: claim, isSuccess, isLoading } = useClaim(Number(claimsId)) ?? {};
  if (!isLoading) {
    if (isSuccess && claim) {
      return (
        // claim must be defined in order to load ClaimDetails
        <ClaimDetails {...claim} />
      );
    } else {
      // error
      return (
        <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <h3>An error has occurred, please select another claim or try again later.</h3>
        </div>
      );
    }
  } else {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Loading />
      </div>
    );
  }
};

export const ClaimDetails = (claim: IClaim) => {
  const rawReferrer = useReferrer();
  const referrer = rawReferrer || '/admin/claims';
  const { claimsId } = useParams();
  const { copy, copied } = useClipboard({ copiedTimeout: 1000 });
  const [attachmentsPage, setAttachmentsPage] = useState(1);
  const { data: administratorsData } = useAdministrators();
  const userRole = useUserRole();
  const userContext = useUserContext();
  const { isClaimAdmin } = userContext;
  const { isAdmin, isThirdPartyAdmin } = userRole;
  const [administrator, setAdministrator] = useState(claim.administrator);
  const [claimStatus, setClaimStatus] = useState<ClaimStatus>(claim.status); // track current claim status, used for ClaimTag
  const { addToast } = useToast();
  const { mutate: updateClaimsMutation, isLoading, isSuccess } = useMutation(withdrawClaim, {});
  const [statusError, setStatusError] = useState<string>('');
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState<boolean>(false);
  const userCanWithdrawClaim = claim.actions.withdraw;
  const userCanUpdateAmount = claim.actions.update_claim_amount;
  const userCanEditClaim = claim.actions.edit;
  const claimNewOrProcessing = claim.status === ClaimStatus.NEW || claim.status === ClaimStatus.PROCESSING;
  const [isClaimWithdrawModalOpen, setIsClaimWithdrawModalOpen] = useState(false);

  const canSeeApprovableClaimAmount = (isAdmin || isThirdPartyAdmin || isClaimAdmin) && claimNewOrProcessing;

  const showWithdrawButton: boolean =
    userCanWithdrawClaim && !isSuccess && (claimNewOrProcessing || claim.status === ClaimStatus.ACCEPTED)
      ? true
      : false;

  const closeModal = () => {
    setShowModal(false);
    setStatusError('');
  };

  const statusErrorsNotPresent: boolean = statusError == '' ? true : false;
  const navigate = useNavigate();

  const closeWithdrawModal = () => {
    setIsClaimWithdrawModalOpen(false);
  };

  const subrogationDetailsStatuses = [
    ClaimStatus.PAID,
    ClaimStatus.SUBROGATION,
    ClaimStatus.SUBROGATION_PAID_IN_FULL,
    ClaimStatus.COLLECTIONS,
    ClaimStatus.COLLECTIONS_PAID,
    ClaimStatus.COLLECTIONS_UNPAID,
    ClaimStatus.PAYMENT_PLAN,
    ClaimStatus.PAYMENT_PLAN_IN_PROGRESS
  ];

  const showSubrogationDetails: boolean = subrogationDetailsStatuses.includes(claim.status);

  const isAuthorizedForNotes = isAdmin || isThirdPartyAdmin;

  const claimAvailableStatuses = claim.actions?.available_statuses?.filter(({ label }) => !!label) || [];
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [selectedFileData, setselectedFileData] = useState<IClaimAttachment>();
  const handleAttachmentModal = (attachment) => {
    return navigate(`/${getClaimAttachmentPath({ claimId: claimsId ?? '', blob_id: attachment.blob_id })}`, {
      state: attachment
    });
  };
  const downloadAttachment = (attachmentUrl, attachmentName) => {
    try {
      const link = document.createElement('a');
      link.href = `${attachmentUrl}`;
      link.setAttribute('download', attachmentName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      addToast('Something went wrong when trying to download the file', 'neutral_warning', TOAST_STATUS.ERROR);
    }
  };
  const {
    isSuccess: attachmentsSuccess,
    isLoading: attachmentsLoading,
    data
  } = useClaimAttachments(claim.id, { option: 'include_notes', page: attachmentsPage }) ?? {};
  const shouldDisplayAttachments = attachmentsSuccess && (data?.meta.total_count ?? 0) > 0;

  const claimAdminChangeClaimStatus = () => {
    if (isClaimAdmin && claimStatus === ClaimStatus.NEW) {
      claimStatusChange({ label: 'Processing', value: 'processing' });
    }
  };

  const [showEditControls, toggleShowEditControls] = useState<boolean>(!!claim.administrator);
  useEffect(() => {
    setAdministrator(claim.administrator);
    setClaimStatus(claim.status);
    toggleShowEditControls(!!claim.administrator);
  }, [claim]);

  useEffect(() => {
    // call claimAdminChangeClaimStatus once on page load
    claimAdminChangeClaimStatus();
  }, []);

  const assignsAdminToClaim = (administrator_id) => {
    assignAdmin(claim.id, administrator_id).then((v) => {
      const newAdmin = administratorsData?.administrators.find((admin) => admin?.id === administrator_id);
      setAdministrator(newAdmin!);
    });
  };

  const { mutate: changeClaimStatus } = useUpdateClaimStatus();
  const withdrawSelectedClaim = () => {
    setIsClaimWithdrawModalOpen(false);
    updateClaimsMutation(claim, {
      onSuccess: (response) => {
        setClaimStatus(response.data.status);
        addToast('Claim successfully withdrawn', '', TOAST_STATUS.SUCCESS);
        queryClient.invalidateQueries('claims');
        queryClient.invalidateQueries(['claim', claim.id]);
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 422) {
          setShowModal(true);
          for (var key in error?.response?.data.claim.errors) {
            if (error?.response?.data.claim.errors.hasOwnProperty(key)) {
              var value = key.concat(' - ').concat(error?.response?.data.claim.errors[key]);
              setStatusError(statusError.concat(value));
            }
          }
        }
        addToast('Something went wrong', 'neutral_warning', TOAST_STATUS.ERROR);
      }
    });
  };
  const handleEditToggleInteraction = () => {
    toggleShowEditControls(!showEditControls);
  };

  const claimStatusChange = (value) => {
    changeClaimStatus(
      { id: claim.id, selectedStatus: value },
      {
        onSuccess: (response) => {
          setClaimStatus(response.data.status);
          addToast(
            `Claim status successfully changed to ${generateMaskedStatus(response.data.status, userRole)}`,
            '',
            TOAST_STATUS.SUCCESS
          );
          queryClient.invalidateQueries('claims');
          queryClient.invalidateQueries(['claim', claim.id]);
        },
        onError: (error: AxiosError) => {
          if (error.response?.status === 422) {
            setShowModal(true);
            for (var key in error?.response?.data.claim.errors) {
              if (error?.response?.data.claim.errors.hasOwnProperty(key)) {
                var value = key.concat(' - ').concat(error?.response?.data.claim.errors[key]);
                setStatusError(statusError.concat(value));
              }
            }
          }
          addToast('Something went wrong', 'neutral_warning', TOAST_STATUS.ERROR);
        }
      }
    );
  };

  const renderAttachmentsAndNotes = () => {
    return (
      <React.Fragment>
        {shouldDisplayAttachments && (
          <React.Fragment>
            <ClaimAttachments
              selectedFile={selectedFileData}
              handleDownload={downloadAttachment}
              handleModal={handleAttachmentModal}
              attachmentsData={data}
              isLoading={attachmentsLoading}
              page={attachmentsPage}
              setPage={setAttachmentsPage}
              isModalOpen={false}
              getNextTabIndex={getNextTabIndex}
              hideTopmostDivider={true}
            />
            <SectionDivider />
          </React.Fragment>
        )}

        <ClaimDetailIndividualWrapper>
          {isAuthorizedForNotes && <NoteAndAttachmentsForm />}
          <ClaimNotes handleModal={handleAttachmentModal} handleDownload={downloadAttachment} />
        </ClaimDetailIndividualWrapper>
      </React.Fragment>
    );
  };

  // UX improvement based on feedback from Claims team: use standard outline to highlight
  // current file in Claims Attachment section, when the list of attachments is too large
  // (like 100+ files). Without that it's hard to scroll up and down to see what was the
  // last file you clicked on in the list.
  //
  // For this trick we use N offsets (defined by groupId, which is the number starting at 0)
  // for tab indices below: 1000, 2000, and so on.
  const tabIndexGroups = {};
  const getNextTabIndex = (groupId) => {
    tabIndexGroups[groupId] = tabIndexGroups[groupId] || 1000 + 1000 * groupId;
    return tabIndexGroups[groupId]++;
  };

  return (
    <ClaimsDetailWrapper>
      <FullScreenModal
        isOpen={statusErrorsNotPresent ? isClaimWithdrawModalOpen : showModal}
        closeModal={statusErrorsNotPresent ? closeWithdrawModal : closeModal}
        children={
          statusErrorsNotPresent ? (
            <Fragment>
              <StyledModal>
                <StyledHeader>
                  <StyledModalTitle>Are you sure you want to withdraw the claim?</StyledModalTitle>
                </StyledHeader>
                <StyledModalSubtitle>This action can not be undone.</StyledModalSubtitle>
                <ButtonGroup>
                  <SecondaryButton type="button" onClick={() => closeWithdrawModal()} aria-label="close">
                    Never mind
                  </SecondaryButton>
                  <PrimaryButton
                    type="button"
                    onClick={withdrawSelectedClaim}
                    style={{ marginLeft: '8px' }}
                    aria-label="confirm"
                  >
                    {'Withdraw'}
                  </PrimaryButton>
                </ButtonGroup>
              </StyledModal>
            </Fragment>
          ) : (
            <Fragment>
              <StyledModal>
                <DrawerModalHeader>Unable to change claim status</DrawerModalHeader>
                <DrawerModalText>
                  You cannot make this status change until the following action has been completed:
                </DrawerModalText>
                {statusError && <DrawerModalText>{statusError}</DrawerModalText>}
                <DrawerModalFooter>
                  <SquareButton onClick={() => closeModal()} variant="secondary">
                    Close
                  </SquareButton>
                </DrawerModalFooter>
              </StyledModal>
            </Fragment>
          )
        }
      />
      {showAttachmentModal && (
        <FileViewer
          selectedFile={selectedFileData}
          closeModel={() => setShowAttachmentModal(false)}
          downloadFile={downloadAttachment}
        >
          <ClaimAttachments
            selectedFile={selectedFileData}
            handleDownload={downloadAttachment}
            handleModal={handleAttachmentModal}
            attachmentsData={data}
            page={attachmentsPage}
            isLoading={attachmentsLoading ?? false}
            setPage={setAttachmentsPage}
            isModalOpen={true}
            getNextTabIndex={getNextTabIndex}
          />
        </FileViewer>
      )}
      <ClaimDetailWrapper>
        <ClaimDetailViewWrapper>
          <StyledReferrerLink to={referrer} aria-label="collapse">
            <MinimizeIcon />
          </StyledReferrerLink>
          <StyledCopyLinkButton onClick={() => copy(window.location.href)}>
            <LinkIcon />
            {copied && <StyledCopyConfirmationSpan>copied!</StyledCopyConfirmationSpan>}
          </StyledCopyLinkButton>
        </ClaimDetailViewWrapper>
        <DateCreatedWrapper>
          <StyledDateCreatedSpan>{`Date Created: ${stringifyDate(
            initializeDate(claim.created_date)
          )}`}</StyledDateCreatedSpan>
          {isAdmin && (
            <DropdownMenu.Root>
              <DropdownMenu.Trigger aria-label="select admin" style={{ border: 'none' }}>
                {administrator && administrator.initials ? (
                  <StyledAssigneeInitialsP>{administrator.initials}</StyledAssigneeInitialsP>
                ) : (
                  <PersonCircleIcon />
                )}
              </DropdownMenu.Trigger>
              <DropdownContent>
                {administratorsData &&
                  administratorsData.administrators.map((admin, index) => {
                    const isSelected = administrator && administrator.id == admin.id;
                    return (
                      <DropdownItem key={index} disabled={isSelected} onSelect={() => assignsAdminToClaim(admin.id)}>
                        {admin.name_or_email}
                        {isSelected && (
                          <i>
                            <SelectedDropdownCheckmark />
                          </i>
                        )}
                      </DropdownItem>
                    );
                  })}
                {administrator && administrator.id && (
                  <React.Fragment>
                    <DropdownMenu.Separator as={DropdownMenuSplitter}></DropdownMenu.Separator>
                    <DropdownMenu.Item aria-label="unassign claim" onSelect={() => assignsAdminToClaim(null)}>
                      Unassign Claim
                    </DropdownMenu.Item>
                  </React.Fragment>
                )}
              </DropdownContent>
            </DropdownMenu.Root>
          )}
          {claimAvailableStatuses.length > 0 ? (
            <DropdownMenu.Root>
              <DropdownMenu.Trigger aria-label="select claim status" style={{ border: 'none' }}>
                <ClaimTag status={claimStatus} maskedStatus={generateMaskedStatus(claimStatus, userRole)} />
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {claimAvailableStatuses.map((status: IClaimStatusOption, index: number): JSX.Element => {
                  return (
                    <DropdownItem
                      key={index}
                      onSelect={() => {
                        claimStatusChange(status);
                      }}
                    >
                      {status.label}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          ) : (
            <ClaimTag status={claimStatus} maskedStatus={generateMaskedStatus(claimStatus, userRole)} />
          )}
        </DateCreatedWrapper>
      </ClaimDetailWrapper>

      {claim.status_action && <ClaimAction statusAction={claim.status_action} claimStatusChange={claimStatusChange} />}

      <ClaimDetailIndividualWrapper>
        <div>
          {claim.status_notification && <ClaimNotification statusNotification={claim.status_notification} />}
          <StyledClaimDetailsItem>
            <ClaimTitleWrapper>{getClaimTitle(claim)}</ClaimTitleWrapper>
            <StyledOtherClaimTag>{claim.property_owner.claim_classification}</StyledOtherClaimTag>
          </StyledClaimDetailsItem>
          {canSeeApprovableClaimAmount && claim.adjusted_amount_cents && (
            <ApprovedClaimAmountCard adjusted_amount_cents={claim.adjusted_amount_cents} />
          )}
          {(userCanEditClaim || showWithdrawButton || userCanUpdateAmount) && (
            <AdminEditWrapper aria-label="more">
              <ActionButtons
                showWithdraw={showWithdrawButton}
                showUpdateClaimAmount={userCanUpdateAmount}
                showEdit={userCanEditClaim}
                withdrawClaim={() => setIsClaimWithdrawModalOpen(true)}
                claimId={Number(claim.id)}
                isLoading={isLoading}
              />
            </AdminEditWrapper>
          )}
          {/* CLAIM DETAIL CARD */}
          <ClaimDetailsCard {...claim} />

          {/* POLICY DETAIL CARD */}
          <PolicyDetailsCard {...claim} />
          {(isAdmin || isThirdPartyAdmin) && showSubrogationDetails && <SubrogationDetails />}
          <TenantNotification isTenantNotified={claim.tenant_notified_of_rent_arrears}>
            <Icon isTenantNotified={claim.tenant_notified_of_rent_arrears}>
              {claim.tenant_notified_of_rent_arrears && <CheckIcon height={14} width={14} />}
            </Icon>
            Tenant has been notified of claim
          </TenantNotification>
        </div>
      </ClaimDetailIndividualWrapper>

      <Tabs>
        <SectionDivider />
        <TabList>
          <Tab>Notes and Attachments</Tab>
          {isAdmin && <Tab>Change Log</Tab>}
        </TabList>
        <TabContent>{renderAttachmentsAndNotes()}</TabContent>
        {isAdmin && (
          <TabContent>
            <ClaimChangelog claimId={claim.id} />
          </TabContent>
        )}
      </Tabs>
    </ClaimsDetailWrapper>
  );
};

export default ClaimDetails;
