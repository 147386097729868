import { csrfToken } from 'utils/document';
import axios from 'axios';
import { Coverage, FormInputValues, property_types } from './types';
import { ICoverageOptionsRaw } from '../../interfaces';
import { QueryObserverResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { integrationTypes, Value } from './select';

export const SPU_PROPERTY_CACHE_KEY: string = 'getProperty';

type Property = {
  property: FormInputValues;
};

export type Response = {
  id: number;
  building_name?: string;
  address_line_one?: string;
  address_city?: string;
  address_zip?: string;
  address_state?: string;
  property_type?: property_types;
  coverage_details: {
    label: string;
    type: Coverage | string;
    value: string | number;
  };
  property_owner?: Value;
  policy_holder?: Value;
  google_place_id?: string;
  integration_type?: typeof integrationTypes[number];
  integration_code?: number | string;
  units: string[];
  cash_deposit_enabled: boolean;
  deposify_partner_bank_account_disabled: boolean;
  uses_partner_enrollment: boolean;
  stripe_bank_account_id: string | null;
  stripe_bank_account_display_name: string | null;
  renter_cash_deposit_portal_name: string | null;
  renter_cash_deposit_portal_url: string | null;
  renters_insurance_offerable: boolean;
  renters_insurance_enabled: boolean;
};

type SingleRes = {
  property: Response;
};

type PMRes = {
  property_managers: { id: number; email: string }[];
};

type LARes = {
  listing_agents: { id: number; email: string }[];
};

type UnitRes = {
  units: { id: number; name: string }[];
};

const createProperty = async (property: FormInputValues) => {
  const authenticity_token = csrfToken();
  const data: Property = { property };
  const res = await axios.post(`/v2/admin/properties`, data, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });
  return res;
};

const editProperty = async ({ id, ...property }: FormInputValues) => {
  const authenticity_token = csrfToken();
  const data: Property = { property };
  const res = await axios.put(`/v2/admin/properties/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });
  return res;
};

const syncProspects = async (property_id: string = '') => {
  const authenticity_token = csrfToken();
  const res = await axios.get(`/v2/admin/properties/${property_id}/sync_prospects`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });
  return res;
};

export const useSyncProspects = () => {
  const { mutate, ...rest } = useMutation(syncProspects);
  return {
    syncProspects: mutate,
    ...rest
  };
};

export const useSPUMutation = () => {
  const { mutate: addProperty, ...rest } = useMutation(createProperty);
  return {
    addProperty,
    ...rest
  };
};

export const useSPUEditMutation = () => {
  const { mutate, ...rest } = useMutation(editProperty);
  return {
    editProperty: mutate,
    ...rest
  };
};

export default createProperty;

const fetchProperty = async (id: string) => {
  const { data } = await axios.get<SingleRes>(`/v2/admin/properties/${id}.json`);
  return data.property;
};

const fetchPropertyManagers = async (property_id: string) => {
  const { data } = await axios.get(`/v2/admin/properties/${property_id}/property_managers`);
  return data;
};

const fetchListingAgents = async (property_id: string) => {
  const { data } = await axios.get(`/v2/admin/properties/${property_id}/listing_agents`);
  return data;
};

const fetchUnitNames = async (property_id: string, all: boolean) => {
  const { data } = await axios.get(`/v2/admin/properties/${property_id}/units.json?all=${all}`);
  return data;
};

export function useProperty(id: string = ''): QueryObserverResult<Response> {
  return useQuery([SPU_PROPERTY_CACHE_KEY, id], () => fetchProperty(id));
}

export function usePropertyManagers(property_id: string = ''): QueryObserverResult<PMRes> {
  return useQuery(['getPropertyManager', property_id], () => fetchPropertyManagers(property_id));
}

export function useListingAgents(property_id: string = ''): QueryObserverResult<LARes> {
  return useQuery(['getListingAgents', property_id], () => fetchListingAgents(property_id));
}

export const PROPERTY_UNITS_KEY = 'propertyUnits';
export function usePropertyUnits(property_id: string = '', all: boolean = false): QueryObserverResult<UnitRes> {
  return useQuery([PROPERTY_UNITS_KEY, property_id], () => fetchUnitNames(property_id, all));
}

export const fetchPropertyOwner = async (param: string = '') => {
  const { data } = await axios.get(`/v2/admin/property_owners/search?query=${encodeURIComponent(param)}&limit=10`);
  return data;
};

export const fetchPolicyHolder = async (param: string = '') => {
  const { data } = await axios.get(`/policy_holders/search?query=${encodeURIComponent(param)}&limit=10`);
  return data;
};

export const fetchMultiplier = async (id?: string | number | null) => {
  const oldOwner = `/v2/admin/property_owners/${id}/coverage_options.json`;
  const newOwner = '/v2/admin/property_owners/coverage_options.json';
  const { data } = await axios.get(id ? oldOwner : newOwner);
  return data?.coverage_options?.multipliers;
};

export const fetchProperties = async (param: string = '') => {
  const { data } = await axios.get(`/v2/admin/properties/search?query=${param}`);
  return data;
};

export const fetchUnits = async (id?: number, param: string = '') => {
  const { data } = await axios.get(`/properties/${id}/units/search?query=${param}`);
  return data;
};

export const createInvitation = (params) => {
  const token = csrfToken();
  return axios.post(`/v2/admin/invitations`, { authenticity_token: token, partial_quote: params });
};

export const getCoverage = async (params?: number) => {
  const { data } = await axios.get<ICoverageOptionsRaw>(`/v2/admin/properties/${params}/coverage_options.json`);
  return data;
};

const required_fields = [
  'building_name',
  'address_line_one',
  'address_city',
  'address_zip',
  'address_state',
  'property_type',
  'coverage_details',
  'property_owner',
  'policy_holder'
];

export const validationFields = (fields: FormInputValues) => {
  let errors = {};
  let errMsg = 'This field is required';
  let coverageAmountError = 'cannot be less than $330';
  required_fields.forEach((field) => {
    if (field === 'coverage_details' && !fields[field]?.value) {
      errors['coverage_details_value'] = errMsg;
    }
    if (
      field === 'coverage_details' &&
      fields.coverage_details.type === 'DOLLAR_AMOUNT' &&
      fields.coverage_details.value !== undefined
    ) {
      if (fields.coverage_details.value < 33000) {
        errors['coverage_details_value'] = coverageAmountError;
      }
    }
    if (field === 'property_owner' && !fields[field]?.name) {
      errors['property_owner_name'] = errMsg;
    }
    if (field === 'policy_holder' && !fields[field]?.name) {
      errors['policy_holder_name'] = errMsg;
    }
    if (field === 'property_type' && fields.property_type === property_types.MULTI && fields?.units?.length === 0) {
      errors['units'] = errMsg;
    }
    if (!fields[field]) {
      errors[field] = errMsg;
    }
  });

  return errors;
};
